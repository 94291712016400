// Mobile accordion style.
.region--off-canvas .ui.accordion .item > .title {
  background-color: #444;
  display: block;
  color: #fff;
  padding: 10px 1em 9px;
  margin: 0;
}

.region--off-canvas .ui.accordion > .item > .title {
  border-top: solid 1px #555;
  border-bottom: solid 1px #222;
}

.region--off-canvas .ui.accordion > .item:first-child > .title {
  border-top: none;
}

.region--off-canvas .ui.accordion > .item > .content {
  background-color: #555;
  color: #fff;
  border-top: solid 1px #555;
  border-bottom: solid 1px #333;
}

// Add the arrow up/down dependend on the current toggle state.

.ui.accordion legend.title,
.ui.accordion .views-exposed-widget > .title,
.ui.accordion .item.collapsed > .title,
.ui.accordion .item.expanded > .title {
  border: solid 1px #eee;
  cursor: pointer;
  line-height: 1.05;
  font-size:1.4285714286rem!important;
  font-family: $open-sans;
  padding: rhythm(1) $gutter*0.5!important;
  position: relative;
  color: #666!important;
  font-weight: normal;
  -webkit-tap-highlight-color: transparent;
  @include icon(arrow-down2, before) {
    float: right;
  }
  &.active {
    border: solid 1px #b6c62e;
    color: #000!important;
    @include icon(arrow-up, before) {
      float: right;
    }
    .region--off-canvas & {
      background-color: inherit;
      border-top: solid 1px #555;
      border-bottom: solid 1px #222;
      border-left: none;
      border-right: none;
    }
  }
  .region--off-canvas & {
    border-top: solid 1px #555;
    border-bottom: solid 1px #222;
    border-left: none;
    border-right: none;
    color: #fff!important;
    font-size: inherit!important;
    line-height: inherit;
    padding: 10px 1em 9px!important;
    background-color: #444;
  }
}



// Inner accordion style.
.region--off-canvas .ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),
.region--off-canvas .ui.accordion:not(.styled) .title ~ .content:not(.ui) {
  list-style: none;
  padding: 0;
  margin: 0;
  .title {
    background-color: #555;
  }
}

.ui.accordion .title:not(.ui) {
  .region--off-canvas & {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-weight: normal;
  }
}
