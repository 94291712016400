.user-login-form {
  ul, li {
    margin: 0;
    padding: 0;
  }
  li a:before {
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
  li:nth-child(1) a {
    @include icon(user);
  }
  li:nth-child(2) a {
    @include icon(key);
  }
  .form-item {
    clear: both;
  }
  input[type="text"],
  input[type="password"] {
    width: 100%;
  }
  .item-list {
    margin-bottom: rhythm(1);
  }
  .form-submit {
    display: block;
    width: 100%;
    text-align: center;
  }
  .form-item label {
    float: none;
    width: auto;
    display: block;
    line-height: rhythm(1);
    margin: rhythm(0.25) 0;
  }
}

// Adjsut off-canvas styles.
.region--off-canvas .user-login-form {
  padding: rhythm(0.5) ($gutter*0.5) 0;
}

.region--off-canvas .user-login-form .form-item label {
  @include font-size(13px);
  color: #fff;
}
