.node--recipe--teaser {
  @include clearfix;
  margin-bottom: rhythm(2);
  @include mq($tab, true) {
    margin-bottom: rhythm(1);
  }
}

.node--recipe--teaser .group-left {
  @include clearfix;
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(4);
    margin-bottom: 0;
  }
}

.node--recipe--teaser .group-left img {
  width: 100%;
}

.node--recipe--teaser .group-right {
  @include mq($tab, true) {
    @include span-columns(10);
    @include shift(0.5);
    @include omega;
  }
}

.node--recipe--teaser .field--recipe-category {
  display: inline-block;
  @include mq($desk, true) {
    margin-top: rhythm(1);
  }
}
