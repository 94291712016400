.contact--openers {
  @include clearfix;
}

.contact--opener {
  border: solid 1px $light;
  text-align: center;
  padding: rhythm(1) $gutter;
  margin-bottom: rhythm(0.5);
  @include mq($tab-only) {
    @include span-columns(4 of 8);
    @include omega(2);
    margin-bottom: rhythm(1);
    padding: rhythm(2) ($gutter * 2);
  }
  @include mq($desk, true) {
    @include span-columns(4 of 16);
    @include omega(4);
    padding: rhythm(2) $gutter;
    margin-top: 0;
    margin-bottom: rhythm(1);
  }
  @include mq($desk-plus, true) {
    margin-top: rhythm(1);
    margin-bottom: rhythm(2);
  }
}

.contact--opener__title:before {
  background-color: $green2;
  border-radius: 50%;
  display: block;
  color: #fff;
  width: 75px;
  height: 75px;
  margin: 0 auto rhythm(1);
  padding: 15px 0 0;
}

.contact--opener:nth-child(1) .contact--opener__title {
  @include icon(location, before, 30px);
}

.contact--opener:nth-child(2) .contact--opener__title {
  @include icon(phone, before, 30px);
}

.contact--opener:nth-child(3) .contact--opener__title {
  @include icon(info, before, 30px);
}

.contact--opener:nth-child(4) .contact--opener__title {
  @include icon(envelop, before, 30px);
}

.formular--full {
  @include mq($tab) {
    margin-top: rhythm(1);
  }
  @include mq($desk, true) {
    margin-top: rhythm(2);
  }
}

.formular__title {
  background-color: $green2;
  color: #fff;
  padding: rhythm(1) ($gutter*0.5);
  margin-bottom: rhythm(1);
  @include mq($desk, true) {
    margin-bottom: rhythm(2);
  }
}

.formular--full .field--form-body {
  @include mq($desk, true) {
    @include span-columns(7);
  }
}

.formular--full .webform-client-form {
  margin-top: rhythm(1);
  @include mq($desk, true) {
    @include span-columns(8);
    @include omega;
    margin-top: 0;
  }
}
