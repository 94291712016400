.party--teaser {
  @extend .news--teaser;
}

.not-front .party--teaser .party__title {
  @include mq($desk, true) {
    margin-top: rhythm(1);
  }
}

.party--teaser .party__content {
  @include mq($tab, true) {
    @include span-columns(9.5);
    @include shift(0.5);
    @include omega;
  }
}
