.block--menu-menu-sb-meta-menu {
  @include outer-container();
}

.block--menu-menu-sb-meta-menu .menu {
	float: right;
	a {
		color: #333;
	  @include font-size(12px);
    margin-left: $gutter*0.5;
    &:hover,
    &.active {
      color: $green;
    }
	}
}

// We render the meta menu on mobile in the off-canvas navigation a second time. Let's just inherit the stlyes from
// the sb-sticky menu.
.block--menu-menu-sb-meta-menu--2,
.block--menu-menu-sb-meta-menu--3,
.block--menu-menu-sb-meta-menu--4,
.block--menu-menu-sb-meta-menu--5 {
  @extend .block--menu-menu-sb-meta-menu;
}
