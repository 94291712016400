ul.tabs--secondary {
  @include clearfix;
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.tabs--secondary li {
  float: left;
}

ul.tabs--secondary li a {
  display: block;
  padding: rhythm(0.5);
}

ul.tabs--secondary a:hover {
  background-color: #eee;
}

ul.tabs--secondary a.active {
  background-color: #57ab55;
  color: white;
  &:hover {
    background-color: #57ab55;
  }
}
