.region--push {
  background-color: $green-light;
  @include outer-container();
  margin-top: rhythm(1);
  padding: {
    top: rhythm(0.5);
    right: $gutter*0.5;
    bottom: rhythm(0.5);
    left: $gutter*0.5;
  }
  margin: {
    left: 0;
    right: 0;
  }
  @include mq($tab) {
    padding: {
      top: rhythm(1);
      right: $gutter;
      bottom: rhythm(1);
      left: $gutter;
    }
  }
  @include mq($desk-plus, true) {
    margin-top: rhythm(2);
    margin: {
      right: auto;
      left: auto;
    }
    padding: {
      top: rhythm(2);
      right: rhythm(2);
      bottom: rhythm(2);
      left: rhythm(2);
    }
  }
}
