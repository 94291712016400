.region--icon-menu {
  background: $slideshow-background-image no-repeat center center;
  background-size: cover;
  padding: rhythm(0.5) 0;
  @include mq($tab, true) {
    background: none;
    padding: rhythm(3) 0;
  }
}

.page-erlebniswelt .region--icon-menu {
  @include mq($tab, true) {
    padding: rhythm(1.5) 0;
  }
}
