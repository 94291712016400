.l-main {
  position: relative;
}

.not-front .l-main-inner {
  background-color: #fff;
  @include outer-container;
  position: relative;
  margin: {
    top: 0;
    bottom: rhythm(0.5);
  };
}

.not-front.no-breadcrumb .l-main-inner {
  margin-top: 0;
}
