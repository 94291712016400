//$typecsset-base-font-size:      14px;
//$typecsset-base-line-height:    17.5px;
//
//$base-font-size: 14px;
//$em-base: $base-font-size;

//img {
//  display: none;
//}

@use "sass:math";

@mixin font-size($font-size, $line-height: true) {
  @include typecsset-font-size($font-size, $line-height);
}

$breakpoints: $tab, $desk;
$breakpoints: 'tab', 'desk';

$breakpoints: (
        default: (
                breakpoint: null,
                font-size: 14px,
                line-height: 21px,
        ),
        tab: (
                breakpoint: $tab,
                font-size: 14px,
                line-height: 21px,
        ),
        desk: (
                breakpoint: $desk,
                font-size: 14px,
                line-height: 21px,
        ),
);
$typecsset-show-baseline: false;
//$typecsset-show-baseline: true;

// @todo: cleanup.
// @todo: move this to e6h
$map-keys: map-keys($breakpoints);
@each $key in $map-keys {
  $i: 0;
  $breakpoint-settings: map-get($breakpoints, $key);
  $no-query-fallback: false;
  @if ($i == length($breakpoints)) {
    $no-query-fallback: true;
  }
  @if (map-get($breakpoint-settings, breakpoint) == null) {
    $typecsset-base-font-size: map-get($breakpoint-settings, font-size);

    $typecsset-base-line-height: $typecsset-base-font-size + ($typecsset-base-font-size * 0.5);
    @if map_has_key($breakpoint-settings, line-height) {
      $typecsset-base-line-height: map-get($breakpoint-settings, line-height);
    }
    $typecsset-magic-ratio:         math.div($typecsset-base-line-height, $typecsset-base-font-size);
    $em-base: $typecsset-base-font-size;
    html {
      font-size: $typecsset-base-font-size;
      line-height: math.div($typecsset-base-line-height, $typecsset-base-font-size);
    }
    body {
      //      @include typecsset-font-size($typecsset-base-font-size);
    }
  } @else {
    @include mq(map-get($breakpoint-settings, breakpoint), $no-query-fallback) {
      $typecsset-base-font-size: map-get($breakpoint-settings, font-size);

      $typecsset-base-line-height: $typecsset-base-font-size + ($typecsset-base-font-size * 0.5);
      @if map_has_key($breakpoint-settings, line-height) {
        $typecsset-base-line-height: map-get($breakpoint-settings, line-height);
      }
      $typecsset-magic-ratio:         math.div($typecsset-base-line-height, $typecsset-base-font-size);
      $em-base: $typecsset-base-font-size;
      html {
        font-size: $typecsset-base-font-size;
        line-height: math.div($typecsset-base-line-height, $typecsset-base-font-size);
      }
      body {
        //        @include typecsset-font-size($typecsset-base-font-size);
      }
    }
  }
  $i: $i + 1;
}

@mixin visual-baseline($background-color: transparent) {
  @if $typecsset-show-baseline == true {
    $typecsset-baseline-size: $typecsset-base-line-height;
    @include background-image(linear-gradient($background-color rem($typecsset-baseline-size - 2), #A6FFED rem($typecsset-baseline-size - 1), $background-color rem($typecsset-baseline-size)));

    background-size: auto rem($typecsset-baseline-size);
    background-repeat: repeat;
  }
}

@mixin dtransition($value) {
  @include transition($value 0.3s ease-in-out);
}

.region {
//  @include visual-baseline;
}
.region--main {
  background: none;
}

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}
//
//body {
//  //  color: blue;
//  @include mq($desk, true) {
//    //    color: red;
//  }
//}
a {
  text-decoration: none;
}

img {
  float: left;
  height: auto;
  max-width: 100%;
}


hr {
//  clear: both;
//  border: none;
//  border-bottom: solid 1px $hr;
//  margin: 0 0 rhythm(0.5);
//  padding: 0 0 rhythm(0.5);
}
//
//
//$ms-base: 1rem 2rem;
//// Double stranded modular scale
//$ms-ratio: golden;
//
//body {
////  background-color: $body--background-color;
//  color: $body--text-color;
////  font-size: modular-scale(0);
//  font-family: $body--font-family;
//}
//
h1, h2, h3, h4, h5 {
//  font-family: $headline--font-family;
  font-weight: 900;
  color: #000;
  a {
    color: inherit;
  }
  //  margin: 0;
}


body {
  font-family: $pt-sans;
  color: $medium;
}
h1, h2, h3, h4, h5, h6 {
  color: $dark;
  font-weight: normal;
  margin: 0;
}

h1, h2 {
  font-family: 'Handlee', cursive;
}
h1 {
  @include font-size(32px, false);
  line-height: rhythm(1.5);
  margin-bottom: rhythm(.5);
}
h2 {
  @include font-size(24px, false);
  line-height: rhythm(1.5);
  margin-bottom: rhythm(.5);
  //@include mq($tab, true) {
  //  @include font-size(24px);
  //}
}

.description ul li,
p + ul li {
  list-style: none;
  margin-bottom: rhythm(0.5);
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    background-color: $green2;
    height: 5px;
    width: 5px;
    position: absolute;
    top: 8px;
    left: -14px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
p + ul,
h3 + p,
ul + p,
p + p {
  margin-top: rhythm(1);
}
p img { margin: 0; }

em { font-style: italic; }
strong { font-weight: bold; color: $dark; }
small { font-size: 80%; }

a:active {
  outline: 0px none;
}
a:focus {
  -moz-outline-style: 0px none;
}

h2 a {
  color: $orange;
}
h2 a:visited {
  //color: inherit;
}
a, a:visited {
  //color: $orange;
  text-decoration: none;
  outline: 0px none;
  @include transition(all 0.3s ease-in-out);
  &:hover, &:focus, &.active {
    color: $green2;
  }
}
p a, p a:visited {
  text-decoration: underline;
  line-height: inherit;
}


//h1 {
////  font-family: $sans-serif;
//  font-weight: 900;
//  @include font-size(32px, false);
//  line-height: rhythm(1.5);
//  text-transform: uppercase;
//  @include rhythm(0, 0.25, 0.25, 0);
//}
//h3 {
//  @include font-size(24px, false);
//  line-height: rhythm(1.25);
//  @include rhythm(0, 0.25, 0.5, 0);
//}
%headline-color {
//  color: $headline--color;
//  a {
//    color: $headline--color;
//  }
//  a:hover {
//    color: $green;
//  }
}
//
////h1 {
////  font-size: modular-scale(5);
////}
////
////h2 {
////  font-size: modular-scale(4);
////}
////
////h3 {
////  font-size: modular-scale(3);
////}
////
////h4 {
////  font-size: modular-scale(2);
////}
////
////h5 {
////  font-size: modular-scale(1);
////}
//
//
// Typographic stuff.
p, ul {
//  font-family: $serif;
  @include line-height(1);
  margin: 0;
}
ul {
  margin-top: rhythm(0.5);
  margin-bottom: rhythm(0.5);
  margin-left: $gutter;
}
p img {
  float: none;
  clear: both;
}
p.submitted {
//  font-family: $sans-serif;
}
a {
  color: $green;
}

a {
  @include transition(background-color 0.2s ease-in-out, color 0.2s ease-in-out);
}

.region--content {

//  ul {
//    margin: 0;
//  }

}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

code,
pre {
  font-family: monospace, serif;
  font-size: 1em;
}

blockquote {
  font-style: italic;
}

blockquote:before {
  content: "\201C";
  display: inline-block;
  padding-right: .4em;
}

@media print {

  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  p,
  h2,
  h3 {
    orphans: 3; /* http://css-tricks.com/almanac/properties/o/orphans/ */
    widows: 3; /* http://css-tricks.com/almanac/properties/w/widows/ */
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}


.visually-hidden {
  position: absolute !important;
  clip: rect(1px,1px,1px,1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
