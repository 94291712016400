.region--content-inner {
  @include mq($tab) {
    margin-bottom: rhythm(1);
  }
  @include mq($desk, true) {
    @include span-columns(11);
    @include shift(4);
    @include omega;
    margin-bottom: 0;
  }
  &.content-left {
    @include shift(0);
  }
}

.region--content {
  @include clearfix;
  @include mq($tab, true) {
    margin-bottom: rhythm(1);
    padding: 0;
  }
  @include mq($desk, true) {
    margin-bottom: rhythm(4);
  }
  .region--content {
    margin-bottom: 0;
    margin-top: rhythm(1);
    @include mq($desk, true) {
      margin-top: rhythm(2);
    }
    .no-breadcrumb & {
      margin-top: 0;
    }
  }
}

//.page-home .region--content {
//  @include outer-container;
//  padding: rhythm(0.5) 0;
//  margin-bottom: 0;
//  @include mq($tab, true) {
//    padding: rhythm(2) 0 0;
//  }
//}

.page-home .region--content-inner {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    margin-bottom: rhythm(2);
  }
}
