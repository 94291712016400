@mixin icomoon($font-size: base-font-size) {
  @if ($font-size == base-font-size) {
    $font-size: $em-base;
  }
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  @include font-size($font-size);
}

@mixin icon($name: false, $before: before, $font-size: base-font-size) {
  @if($name == false) {
    @include icomoon($font-size);
  }
  @else if($name == none) {
    &:before, &:after {
      content: '';
    }
  }
  @else if($before == before) {
    &:before {
      @include icomoon($font-size);
      @extend .icon-#{$name};
      @content;
    }
  }
  @else if($before == after) {
    &:after {
      @include icomoon($font-size);
      @extend .icon-#{$name};
      @content;
    }
  }
}

.icon-newspaper {
  content: "\e00c";
}
.icon-cart2 {
  content: "\e0c1";
}
.icon-food {
  content: "\e228";
}
.icon-fire {
  content: "\e23f";
}
.icon-truck {
  content: "\e260";
}
.icon-thumbs-up {
  content: "\e313";
}
.icon-hand {
  content: "\e33f";
}
.icon-marker {
  content: "\e01b";
}
.icon-stack {
  content: "\e096";
}
.icon-cart-checkout {
  content: "\e0cc";
}
.icon-bag {
  content: "\e0d2";
}
.icon-credit {
  content: "\e0d5";
}
.icon-envelop {
  content: "\e0eb";
}
.icon-location {
  content: "\e0f5";
}
.icon-play {
  content: "\e380";
}
.icon-pause {
  content: "\e381";
}
.icon-home {
  content: "\e002";
}
.icon-file {
  content: "\e077";
}
.icon-tags {
  content: "\e0ba";
}
.icon-tags2 {
  content: "\e0bb";
}
.icon-cart {
  content: "\e0c6";
}
.icon-basket {
  content: "\e0ce";
}
.icon-address-book {
  content: "\e0e8";
}
.icon-print {
  content: "\e119";
}
.icon-download {
  content: "\e132";
}
.icon-user {
  content: "\e185";
}
.icon-search {
  content: "\e1ba";
}
.icon-key {
  content: "\e1ca";
}
.icon-inject {
  content: "\e1f4";
}
.icon-remove {
  content: "\e24f";
}
.icon-stack-list {
  content: "\e349";
}
.icon-plus-circle {
  content: "\e35f";
}
.icon-minus-circle {
  content: "\e361";
}
.icon-info {
  content: "\e363";
}
.icon-close {
  content: "\e36d";
}
.icon-checkmark {
  content: "\e372";
}
.icon-exit {
  content: "\e37e";
}
.icon-loop {
  content: "\e3a8";
}
.icon-arrow-down {
  content: "\e3cd";
}
.icon-spinner {
  content: "\e600";
}
.icon-hat {
  content: "\e601";
}
.icon-spotlight {
  content: "\e2e7";
}
.icon-coin-euro {
  content: "\ea0c";
}
.icon-phone {
  content: "\ea1c";
}
.icon-medal {
  content: "\eb9d";
}
.icon-more2 {
  content: "\ec59";
}
.icon-menu {
  content: "\ec6a";
}
.icon-arrow-up {
  content: "\edb4";
}
.icon-arrow-right {
  content: "\edb8";
}
.icon-arrow-down2 {
  content: "\edbc";
}
.icon-arrow-left {
  content: "\edc0";
}
.icon-share3 {
  content: "\eedd";
}
.icon-google {
  content: "\eee3";
}
.icon-facebook {
  content: "\eee8";
}
.icon-twitter {
  content: "\eeed";
}
