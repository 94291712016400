.page-icon-menu {
  @include clearfix;
  text-align: center;
  @include outer-container($mobile-padding: 0);
  @include mq($tab, true) {
    margin-bottom: rhythm(2);
  }
}

.page-icon-menu-item {
  @include content-icons('a', 18px) {
    margin-right: rhythm(0.5);
    @include mq($tab, true) {
      display: block;
      @include font-size(36px);
      margin-right: 0;
      margin-bottom: 6px;
    }
    @include mq($desk, true) {
      @include font-size(50px);
    }
  }
  display: inline;
}

.page-icon-menu-item a {
  background-color: $green2;
  border-top: solid 1px #ffff26;
  border-bottom: solid 1px #6d9100;
  display: block;
  color: #fff;
  @include icon(arrow-right, after) {
    position: absolute;
    right: rhythm(0.5);
    @include mq($tab, true) {
      display: none;
    }
  }
  @include font-size(18px);
  font-family: $pt-sans;
  text-align: left;
  transition: color 0.3s linear;
  padding: rhythm(0.5);
  @include mq($tab, true) {
    border: solid 2px $green2;
    border-radius: 50%;
    display: inline-block;
    width: 130px;
    height: 130px;
    text-align: center;
    margin: 0 30px rhythm(1);
    padding-top: 24px;
  }
  @include mq($desk, true) {
    width: 160px;
    height: 160px;
    margin-right: 116px;
    padding-top: 28px;
  }
  &:hover {
    color: #fff;
    @include mq($tab, true) {
      background-color: #fff;
      border-color: $green2;
      color: $green2;
    }
  }
}
