table {
  border: none;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  @include mq($tab, true) {
    display: table;
  }
  caption {
    text-transform: uppercase;
    margin-bottom: .2em;
  }
  thead {
    font-size: 90%;
    th {
      background-color: $green-light;
      border-right: 2px solid $green-medium;
      padding: rhythm(0.5) ($gutter*0.5);
      vertical-align: top;
    }
  }
  tbody {
    font-size: 90%;
    tr {
      td {
        border: solid 1px $light;
        padding: rhythm(0.5) ($gutter*0.5);
        vertical-align: top;
        a, a:visited {
          color: $dark;
        }
      }
      &.odd,
      &.even {
        background: none;
      }
    }
  }
}

// Disable sticky table headers on mobile devices.
table.sticky-header {
  display: none;
  @include mq($desk, true) {
    display: block;
  }
}
