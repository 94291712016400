.block--sb-shop-shop-region-menu .menu {
  @include outer-container();
  border-bottom: 1px solid #bbb;
  margin: rhythm(2) 0 0;
}

.block--sb-shop-shop-region-menu .menu--item_link {
  border-bottom: solid 4px transparent;
  color: $grey--dark;
  @include font-size(25px);
  height: 45px;
  font-family: $open-sans;
  margin-right: $gutter;
}

.block--sb-shop-shop-region-menu .menu--item_link.active,
.block--sb-shop-shop-region-menu .menu--item_link:hover {
  border-color: #57ab55;
  color: $grey--dark;
}
