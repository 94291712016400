
h2.field--title-field {
  background: #333;
  border-bottom: solid 4px #b6c62e;
  color: #fff;
  @include font-size(26px);
  line-height: 34px;
  font-family: $open-sans;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: rhythm(1.5);
  padding: rhythm(1);
  @include mq($tab, true) {
    text-align: left;
    @include font-size(30px);
  }
}

.page-two-columns h2.field--title-field {
  margin-right: -($gutter*0.25);
  margin-left: -($gutter*0.25);
  @include mq($tab, true) {
    margin-right: 0;
    margin-left: 0;
  }
}

.page-two-columns {
  @include clearfix;
  clear: both;
  margin-top: rhythm(0.5);
  margin-bottom: rhythm(0.5);
  @include outer-container;
  @include mq($tab, true) {
    margin-top: 0;
    margin-bottom: rhythm(1.5);
  }
  .page-two-columns {
    margin-bottom: rhythm(1);
    margin-right: 0;
    margin-left: 0;
    & > .paragraphs-items-field-page-left-column,
    & > .paragraphs-items-field-page-right-column {
      padding: 0;
    }
  }
  &.sidebar-left {
    & > .paragraphs-items-field-page-left-column {
      margin-bottom: rhythm(0.5);
    }
    @include mq($tab, true) {
      & > .paragraphs-items-field-page-left-column {
        @include span-columns(5);
        margin-bottom: 0;
        padding-left: $gutter;
        .page-two-columns .paragraphs-items-field-page-left-column {
          padding-left: 0;
        }
      }
      & > .paragraphs-items-field-page-right-column {
        @include span-columns(10);
        padding: 0 $gutter;
      }
    }
  }
  &.sidebar-right {
    & > .paragraphs-items-field-page-left-column {
      margin-bottom: rhythm(0.5);
    }
    @include mq($tab, true) {
      & > .paragraphs-items-field-page-left-column {
        @include span-columns(10);
        margin-bottom: 0;
        padding-left: $gutter;
        .page-two-columns .paragraphs-items-field-page-left-column {
          padding-left: 0;
        }
      }
      & > .paragraphs-items-field-page-right-column {
        @include span-columns(5);
        padding: 0 $gutter;
      }
    }
  }
}
