
@function px($em, $base: $typecsset-base-font-size) {
  @if not unitless($em) {
    $em: strip-units($em);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return 1 * ($em * $base) + px;
}



//$base-font-size: 14px;
//$em-base: $base-font-size;

$mobile: new-breakpoint(max-width 768px);
$tab: new-breakpoint(min-width 768px);
$tab-only: new-breakpoint(min-width 768px max-width 1023px);
$desk: new-breakpoint(min-width 1024px);
$desk-plus: new-breakpoint(min-width calc(#{$max-width} + #{(px($gutter * 0.5))}));
$ie: new-breakpoint(9999em);


@function line-height($multiplyer: 1, $unit: rem) {
  $value: ($multiplyer * $typecsset-magic-ratio) + rem;
  @return $value;
}

@mixin line-height($multiplyer: 1, $line-height: 'muh') {
  $default: true;
  @if ($line-height == false) {
    line-height: inherit;
    $default: false;
  }
  @if($line-height != false) {
    @if ($line-height != true) {
      @if ($line-height != 'muh') {
        line-height: $line-height * $multiplyer;
        line-height: (rem($line-height) * $multiplyer);
        $default: false;
      }
    }
  }
  @if ($default == true) {
//    line-height: line-height($multiplyer, px);
    line-height: line-height($multiplyer);
  }
}

@mixin rhythm($margin-top: 0, $padding-top: 0, $padding-bottom: 0, $margin-bottom: 0) {
  margin-top: $margin-top * $typecsset-magic-number;
  margin-top: $margin-top * $typecsset-magic-ratio + rem;
  margin-bottom: $margin-bottom * $typecsset-magic-number;
  margin-bottom: $margin-bottom * $typecsset-magic-ratio + rem;
  padding-top: $padding-top * $typecsset-magic-number;
  padding-top: $padding-top * $typecsset-magic-ratio + rem;
  padding-bottom: $padding-bottom * $typecsset-magic-number;
  padding-bottom: $padding-bottom * $typecsset-magic-ratio + rem;
}


@function rhythm($rhythm: 1) {
  @return ($rhythm * $typecsset-magic-ratio) + rem;
}


@mixin outer-container($local-max-width: $max-width, $mobile-padding: ($gutter * 0.5)) {
  @include clearfix;
  max-width: $local-max-width;
  margin: {
    left: $mobile-padding;
    right: $mobile-padding;
  }
  @include mq($tab) {
    margin: {
      left: $mobile-padding * 2;
      right: $mobile-padding * 2;
    }
  }
  @include mq(calc(#{$local-max-width} + #{(px($mobile-padding))}), true) {
    margin: {
      left: auto;
      right: auto;
    }
  }
}


