.region--footer {
  @include outer-container();
  padding-bottom: rhythm(1);
  @include mq($desk-plus, true) {
    padding: {
      top: rhythm(2);
      bottom: rhythm(2);
    }
  }
}
