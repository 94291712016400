.comment-wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.comments__title {
  @extend %pseudo_legend;
}

.indented + .comments__form-title,
.comment + .comments__form-title {
  margin-top: rhythm(1);
}

.comment-form .filter-wrapper {
  display: none;
}

.field-name-comment-body {
  margin-bottom: rhythm(1);
}

.indented .comment-form {
  padding: rhythm(1) $gutter;
}

// Should be the author. We don't need the information showen.
.comment-form > div > div:first-child {
  display: none;
}

  // @TODO TODO TODO TODO: CLEANUP
.node--recipe--teaser .field--title h2 {
  display: inline;
}

.node--recipe--teaser .field--rating {
  display: inline-table;
  position: relative;
  top: 3px;
}

.node--recipe--teaser .fivestar-static-item .description {
  display: none;
}

.product-display--teaser .fivestar-static-item {
  display: inline-block;
  margin: 0 auto;
  .description {
    display: none;
  }
}

.recipe--full .recipe__content > .field--rating .fivestar-static-item,
.product-display--full .fivestar-static-item {
  @include mq($tab, true) {
    float: left;
    margin-right: $gutter;
  }
}

.recipe--full .recipe__content > .field--rating .fivestar-basic,
.product-display--full .fivestar-basic {
  height: rhythm(1);
}

.product-display--teaser .fivestar-basic {
  float: left;
}

.field--is-branding,
.commerce-product-extra-field-sku {
  @include mq($tab, true) {
    margin: rhythm(0.5) 0 rhythm(0.5) 0;
    padding: 6px 0 rhythm(0.5) 0;
    padding-right: $gutter;
  }
  @include mq($desk, true) {
    float: left;
    max-width: 30%;
    margin-top: rhythm(0.5);
    padding-top: 0;
  }
}

.field--recipe-number-wrapper {
  @extend .commerce-product-extra-field-sku;
  .field-label {
    @include mq($tab-only) {
      float: left;
      margin-right: 8px;
    }
  }
}

.comment-post-forbidden {
  overflow: hidden;
}

.recipe--full .field--recipe-description {
  border-top: solid 1px #eee;
  padding: rhythm(1) 0 0;
  overflow: hidden;
  @include mq($desk, true) {
    clear: both;
  }
}

.recipe--full .recipe__content > .field--rating,
.product-display--full .field--rating {
  margin: rhythm(0.5) 0 0;
}

.field--rating {
  overflow: hidden;
}

.field--rating a {
  @include font-size(14px);
}

.fivestar-average-stars:after {
  display: none;
}

.fivestar-average-stars > a {
  display: block;
}
