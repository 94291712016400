.event--full {
  @extend .product-display--full;
}

.event--full .event__content {
  @include mq($desk, true) {
    @include span-columns(10);
  }
}

.event--full .field--product {
  @include mq($tab, true) {
    width: 50%;
    float: right;
  }
}

.event--full .field--event-date {
  @include font-size(16px);
}

.field--event-date .field-label {
  font-weight: bold;
}