.block--ng-shop-shop-search {
  #search-fallback {
    position: relative;
  }
  input[type="search"] {
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: rhythm(2);
    color: #333;
    text-align: center;
    line-height: rhythm(2);
    padding: 0 12px;
    margin: 0;
    &:focus {
      outline: none;
      border-color: $green;
    }
    @include mq($tab, true) {
      text-align: left;
    }
  }

  [type="submit"] {
    background: none;
    border: none;
    @include icon(search);
    position: absolute;
    right: 12px;
    top: 9px;
    span {
      display: none;
    }
  }
}

// We don't want the block title in the off-canvas sidebar.
.region--off-canvas .block--ng-shop-shop-search .block__title {
  display: none;
}

.region--off-canvas .block--ng-shop-shop-search {
  padding: 0 ($gutter*0.5);
}

.region--off-canvas .block--ng-shop-shop-search p {
  color: #fff;
  margin: rhythm(0.75) 0 rhythm(0.25);
}

.region--off-canvas .block--ng-shop-shop-search input[type="search"] {
  text-align: left;
}

// Legacy cause drupal sucks sometimes.
.block--ng-shop-shop-search--2,
.block--ng-shop-shop-search--3 {
  @extend .block--ng-shop-shop-search;
}
