%button, a.btn {
  width: 100%;
  display: block;
  text-align: center;
  @include mq($desk, true) {
    display: inline-block;
    width: auto;
    text-align: left;
  }

  box-sizing: border-box;
  color: #2a2a29;
  text-transform: uppercase;
  padding: 0 12px;
  @include font-size(12px);
  height: rhythm(2);
  line-height: rhythm(2);
  background: white;
  border: 1px solid #dfe0e1;
  transition: all 0.3s ease 0.2s;
  &:hover {
    background-color: $green2;
    color: #fff;
  }

  &:after {
    content: '\25B6';
    font-size: 80%;
    margin-left: 1em;
  }
  &:visited:hover,
  &:hover {
    color: #fff;
    background-color: $green2;
  }
  &:visited {
    color: #2a2a29;
  }
  &.button-continue {
    float: right;
  }
  &.button-back {
    &:before {
      content: '\25C0';
      font-size: 80%;
      margin-right: 1em;
    }
    &:after {
      content: none;
    }
    &:hover {
      color: #fff;
      background-color: $green2;
    }
  }
  &.muted {
    background-color: #f1f1f1;
  }
  &.button-clear {
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
  & + .btn {
    margin-left: ($gutter*0.5);
  }
}

%button2 {
  @extend %button;
  background: $green;

  color: #fff;
  &:hover {
    background-color: $green2;
  }
}
