.product-display--full .back-link {
  margin-bottom: rhythm(0.5);
}

.group-product-allergy-ingredients {
  margin-top: rhythm(1);
  .group-product-allergy-tollerance,
  .group-product-ingredients,
  .group-product-without-ingredients,
  .group-recipe-allergy,
  .group-recipe-complexity {
    float: left;
    width: 100%;
    @include mq($desk, true) {
      width: 33.333%;
    }
    .field-label {
      font-weight: normal;
      border-bottom: solid 1px $light;
      display: block;
      @include font-size(16px);
      @include rhythm(0.5, 0, 0.5, 0.5);
      padding-left: 0;
      &:before {
        padding-right: rhythm(0.5);
      }
    }
  }
  .group-product-allergy-tollerance .field-label {
    @include icon(inject);
  }
  .group-product-ingredients .field-label {
    @include icon(plus-circle);
  }
  .group-product-without-ingredients .field-label {
    @include icon(minus-circle);
  }
  .group-recipe-allergy .field-label {
    @include icon(inject);
  }
  .group-recipe-complexity .field-label {
    @include icon(hat);
  }
}

.group-recipe-allergy-complexity {
  @extend .group-product-allergy-ingredients;
}

.field--product-body {
  ul {
    margin: rhythm(1) 0 0;
    padding: 0;
  }
  li {
    @include icon(checkmark);
    list-style: none;
    &:before {
      background: none;
      height: auto;
      width: auto;
      margin-right: rhythm(0.5);
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.group-product-selection {
  border: solid 1px $green2;
  margin-left: -(rhythm(1));
  margin-right: -(rhythm(1));
  padding: rhythm(1);
  margin-top: rhythm(1);
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    margin-left: 0;
    margin-right: 0;
    padding: rhythm(1.5);
  }
  .stock-status-0 & {
    border-color: $orange;
  }
}

.group-product-buying-info {
  @include mq($tab, true) {
    width: 50%;
    float: left;
  }
}

.commerce-product-extra-field-field-product-size:after {
  content: '//';
  margin-right: 5px;
  display: inline-block;
  margin-left: 5px;
}

.commerce-product-extra-field-field-product-size-unit,
.commerce-product-extra-field-field-product-size {
  display: inline;
}

.product-display--full .field--product-reference {
  @include mq($tab, true) {
    width: 50%;
    float: right;
  }
}

.field--delivery-status,
.commerce-product-extra-field-status {
  span:before {
    background-color: $light;
    border-radius: 50%;
    display: inline-block;
    content: '';
    height: 12px;
    width: 12px;
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
  .available:before {
    background-color: $green2;
  }
  .not-available:before {
    background-color: $orange;
  }
}

.field--is-branding .field-label,
.commerce-product-sku-label {
  display: block;
  font-weight: normal;
  @include font-size(15px);
}

//.commerce-product-sku-label,
.commerce-product-title-label,
.commerce-product-status-label {
  color: $dark;
  font-weight: normal;
}

.group-product-selection {
  @include clearfix;
  @include mq($tab) {
    overflow: hidden;
    clear: left;
  }
  .field--commerce-price {
    @include font-size(32px);
    display: inline-block;
    color: #000;
    margin: rhythm(0.5) 0;
  }
  .form-item-quantity {
    display: block;
  }
  .form-submit {
    background-color: $green2;
    @include icon(basket, after, 18px);
    color: #fff;
    width: 100%;
    text-align: center;
    height: rhythm(3);
    @include font-size(18px);
    &:hover {
      background-color: $green;
    }
  }
}

.form-item-quantity,
.views-field-edit-quantity,
.product-display--full .form-item-quantity {
  @include clearfix;
  margin-top: rhythm(0.5);
  margin-bottom: rhythm(0.5);
  label {
    display: none;
  }
  .commerce-quantity-plusminus-link a {
    border: solid 1px #f1f1f1;
    display: block;
    float: left;
    color: $orange;
    @include font-size(18px);
    text-align: center;
    border-radius: 0;
    margin: 0;
    padding: 10px 0;
    height: 42px;
    width: 42px;
    &:hover {
      background-color: transparent;
      color: $green2;
    }
    &:hover,
    &:focus {
      border: solid 1px $green2;
    }
  }
  &.form-type-textfield input[type="text"],
  .form-type-textfield input[type="text"] {
    float: left;
    width: 43px;
    font-weight: bold;
    margin: 0;
    @include mq($tab, true) {
      width: 60px;
    }
  }
  .commerce-quantity-plusminus-link-disabled a {
    color: $medium;
    &:focus,
    &:hover {
      border: solid 1px #f1f1f1;
      color: $medium;
    }
  }
}

.product-display--full,
.product-display--full header {
  @include mq($desk, true) {
    @include clearfix;
  }
}

.product-display--full .produkte-lifestyle__content,
.product-display--full .product-display__content {
  @include mq($desk, true) {
    @include span-columns(10);
  }
}

// Related products flexslider.
.product-display--full + .block--views {
  margin-top: rhythm(2);
  margin-right: -(rhythm(2));
  margin-left: -(rhythm(2));
}

.product-display--full + .block--views .block__title {
  @include icon(stack-list) {
    margin-right: 6px;
  }
  @include font-size(18px);
  font-family: $pt-sans;
  font-weight: bold;
  text-transform: uppercase;
  padding: rhythm(0.5) rhythm(2) rhythm(0.5);
}

.product-display--full + .block--views .flex-viewport {
  padding: 0 rhythm(2);
  // The flexslider must be full size in order to have the "new product" flag not cut off by overflow:hidden. Therefore
  // we add these white boxes on the left and right side so simulate a margin.
  &:before,
  &:after {
    background-color: #fff;
    content: '';
    display: block;
    height: 100%;
    width: rhythm(2);
    position: absolute;
    top: 0;
  }
  &:before {
    left: 0;
    width: $gutter;
    z-index: 10;
  }
  &:after {
    right: 0;
  }
  img {
    display: inherit;
    width: inherit;
  }
}

// Flexslider navigation.
.product-display--full + .block--views {
  @include greenFlexsliderNavigation;
}
