.tabs--primary {
  @include clearfix;
  list-style: none;
  margin: 0 0 rhythm(0.5);
  @include mq($tab, true) {
    float: right;
    margin: 0 0 rhythm(1);
  }
}

.tabs--primary li {
  display: block;
  @include mq($tab, true) {
    float: left;
  }
}

.tabs--primary li a {
  background-color: $medium;
  display: block;
  color: #fff;
  height: rhythm(1);
  line-height: rhythm(1);
  text-align: center;
  padding: 0 rhythm(1);
  @include mq($tab, true) {
    border-bottom: solid 4px transparent;
    text-align: left;
    height: rhythm(2);
    line-height: rhythm(2);
  }
}

.tabs--primary li a.active {
  background-color: $green;
  color: #fff;
  cursor: default;
  &:hover {
    background-color: $green;
  }
}

.tabs--primary li a:hover {
  background-color: $dark;
}
