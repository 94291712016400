// The technically part of the off-canvas sidebar.
.region--off-canvas {
  clear: both;
  height: 100%;
  width: 310px;
  left: -280px;
  padding-right: 30px;
  position: fixed;
  margin: 0;
  overflow: auto;
  z-index: 98;
  -webkit-overflow-scrolling: touch;
  @include mq($desk, true) {
    display: none;
  }
}

// This is related to .region--off-canvas, it's needed to make the slideout.js thingy work.
.slideout-open,
.slideout-open body {
  overflow: hidden;
}

.slideout-menu {
  display: none;
}

// Off-canvas stylings.
.region--off-canvas-inner {
  background-color: #333;
  height: 100%;
  overflow-y: auto;
  @include mq($desk, true) {
    background-color: transparent;
    overflow-y: inherit;
  }
}

.off-canvas--header {
  background-color: #333;
  display: block;
  width: 100%;
  height: 32px;
}

.off-canvas__navigation {
  background-color: #222;
  height: 100%;
  position: absolute;
  @include mq($desk, true) {
    display: none;
  }
}

.off-canvas__navigation .tabulator__title {
  display: none;
  background-color: #333;
  z-index: 1;
  position: absolute;
  top: 0;
  right: -237px;
  color: #888;
  text-shadow: inset 1px 1px 1px #000;
  line-height: 42px;
  border-bottom: solid 1px;
  width: 280px + 39px;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 1em;
}

.off-canvas__navigation .active .tabulator__title {
  display: block;
}

.off-canvas__navigation .ui.menu.tabular {
  width: inherit;
  float: left;
  border-right: none;
}

.off-canvas__navigation .tabular {
  [data-tab] {
    display: block;
    border-top: solid 1px #444;
    border-bottom: solid 1px #000;
    padding: 11px 1em;
    border-left: solid 2px;
    background-color: #222;
    width: 43px;
    height: 42px;
    color: #f7f7f7;
  }
  .active {
    color: $green;
    background-color: #333;
  }
  .off-canvas--close {
     color: #888;
     @include icon(arrow-left);
     position: relative;
     z-index: 2;
   }
  [data-tab="filter"] {
    @include icon(inject);
  }
  [data-tab="categories"] {
    @include icon(tags);
  }
  [data-tab="cart"] {
    @include icon(cart);
  }
  [data-tab="user"] {
    @include icon(user);
  }
  [data-tab="search"] {
    @include icon(search);
  }
  [data-tab="sticky-menu"] {
    @include icon(menu);
  }
  [data-tab="experience"] {
    @include icon(spotlight);
  }
}

.off-canvas__content {
  margin-left: 43px;
  padding: 43px 0 0;
  @include mq($desk, true) {
    margin-left: 0;
    padding: 0;
  }
}
