.page-sidebar-item {
  border: solid rhythm(1) $grey--light;
  margin-bottom: rhythm(2);
  padding: rhythm(1);
  @include content-icons('.field--page-sidebar-title');
  &:last-of-type {
    margin-bottom: 0;
  }
  .field--page-sidebar-title {
    @extend h2;
    position: relative;
    &:before {
      position: relative;
      left: 0;
      top: -8px;
      margin-right: 9px;
      float: left;
      line-height: 1.5 !important;
    }
  }
}
