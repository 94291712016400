.comment-wrapper {
  @extend %box;
}

.comment {
  border-bottom: solid 1px #eee;
  @include clearfix;
  padding: rhythm(1) $gutter;
  position: relative;
}

.comment__links {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.comment__links li {
  float: left;
}

.comment__links li a {
  background: white;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  color: #2a2a29;
  display: inline-block;
  height: rhythm(1.5);
  line-height: rhythm(1.5);
  padding: 0 $gutter;
  transition: all 0.3s ease 0.2s;
}

.comment__links li:first-child a {
  border-left: 1px solid #eee;
}
.comment__links li a:hover {
  background: $green2;
  color: #fff;
}

.indented {
  border-left: solid 2px $green2;
  margin-left: $gutter * 2;
}

.comment__date {
  float: right;
  @include font-size(13px);
  color: #999;
}

.comment__author {
  margin: 0;
}

.field--comment-body {
  margin: rhythm(0.75) 0 rhythm(0.25);
}
