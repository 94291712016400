.field--page-accordion-title {
  background-color: $green2;
  display: block;
  color: #fff;
  @include font-size(26px);
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: $open-sans;
  font-weight: bold;
  margin-bottom: 5px;
  position: relative;
  @include mq($tab, true) {
    box-shadow: $gutter*0.5 0 0 #b6c62e, -($gutter*0.5) 0 0 #b6c62e;
    display: inline;
    left: 0.84375em;
    text-align: left;
  }
}

.paragraphs-items-field-page-accordion-item {
  margin-top: 5px;
}

.page-accordion-item {
  margin-bottom: rhythm(0.5);
  &:last-child {
    margin-bottom: 0;
  }
}

.field--page-accordion-item-title {
  border: solid 1px #eee;
  cursor: pointer;
  line-height: 1.05;
  @include font-size(20px);
  padding: rhythm(1) $gutter*0.5;
  position: relative;
  @include icon(arrow-down2, before) {
    float: right;
  }
}

.field--page-accordion-item-title.is-open {
  border: solid 1px #b6c62e;
  color: #000;
  @include icon(arrow-up, before) {
    float: right;
  }
}

.field--page-accordion-item-text {
  display: none;
  padding: rhythm(1);
  font-size: 16px;
}

.paragraphs-items-full .page-accordion--full {
  clear: both;
  padding-top: rhythm(1.5);
  margin-bottom: rhythm(1.5);
  @include mq($tab, true) {
    @include outer-container;
  }
}

.page-two-columns .paragraphs-items-full .page-accordion--full {
  padding-top: 0;
  margin-right: 0;
  margin-left: 0;
}
