.breadcrumb--wrapper {
  display: none;
  @include mq($tab, true) {
    border-bottom: 1px solid #dfe0e1;
    display: block;
    margin-bottom: rhythm(1);
  }
}

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb--item {
  display: inline-block;
  color: #5f5f5f;
  &:after {
    content: '>';
    display: inline-block;
    margin: 0 10px;
  }
  &:last-child:after {
    display: none;
  }
}

.breadcrumb--item .breadcrumb--item__nolink,
.breadcrumb--item .breadcrumb--item__link {
  display: inline-block;
  color: #5f5f5f;
  padding: rhythm(0.5) 0;
}

.breadcrumb--item .breadcrumb--item__link:hover {
  color: $green2;
}

.breadcrumb--item__home {
  @include icon(home);
  color: $green;
  span {
    display: none;
  }
}

.breadcrumb--item__shop {
  @include icon(cart);
  color: $orange;
  span {
    display: none;
  }
}

.breadcrumb--item__user {
  @include icon(user);
  color: $green;
  span {
    display: none;
  }
}
