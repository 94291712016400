// Make the address readbale on the delete form page.
.commerce-addressbook-profile-options-delete-form .billing + p {
    border: solid 1px $orange;
    padding: rhythm(1) $gutter;
    margin-bottom: rhythm(1);
}

.view-commerce-addressbook .views-field-rendered-entity {
  border-bottom: solid 1px $light;
  margin-bottom: rhythm(.5);
  padding-bottom: rhythm(.5);
}

.view-commerce-addressbook .views-row-last .views-field-rendered-entity {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
