.block--ng-shop-shop-categories-mobile .accordion {
  padding: 0;
  margin: 0 0 rhythm(1);
}

.block--ng-shop-shop-categories-mobile .ui.accordion {
  margin-bottom: 0;
}

.block--ng-shop-shop-categories-mobile .accordion .item {
    display: block;
}

.block--ng-shop-shop-categories-mobile .accordion  a {
    color: $orange;
}

.block--ng-shop-shop-categories-mobile .collapsed .content {
    display: none;
}

//.block--ng-shop-shop-categories-mobile .content {
.block--ng-shop-shop-categories-mobile .content {
  padding-left: rhythm(1);
}

// Add some padding to the categories list inside the accordion.
.block--ng-shop-shop-categories-mobile .ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui) .title,
.block--ng-shop-shop-categories-mobile .ui.accordion:not(.styled) .title ~ .content:not(.ui) .title {
  padding: 6px 0 4px rhythm(2);
}
