.page--teaser {
  @extend .news--teaser;
}

.not-front .page--teaser .page__title {
  @include mq($desk, true) {
    margin-top: rhythm(1);
  }
}

.page--teaser .page__content {
  @include mq($tab, true) {
    @include span-columns(9.5);
    @include shift(0.5);
    @include omega;
  }
}
