.view-shopping-cart-custom {
  @extend .view-commerce-cart-form;
  // Adjust the "Versandkosten" text in the popup.
  & + div {
    max-width: 50%;
  }
}

.view-shopping-cart-custom .view-header .links.inline {
  text-align: right;
}

.view-shopping-cart-custom .links {
  display: block;
}

.view-shopping-cart-custom tbody tr {
  padding: rhythm(0.5) 0;
}

// Shopping cart in off-canvas on mobile.
.region--off-canvas .view-shopping-cart-custom {
  table {
    border-collapse: collapse;
    margin-top: -2px;
  }
  tbody tr {
    background-color: #444;
    border-top: solid 1px #333;
    border-bottom: solid 1px #555;
    padding: 9px 0;
  }
  td {
    color: #fff;
    padding: 0 8px;
    a {
      color: #fff;
    }
  }
  .views-field-commerce-total {
    height: rhythm(1);
    padding: 0 8px 0 0;
  }
  // Hide the img since we don't have enough space.
  .cart-product-image {
    display: none;
  }
}

.region--off-canvas .view-shopping-cart-custom .view-empty {
  color: #fff;
  padding: rhythm(0.5) ($gutter*0.5);
}
