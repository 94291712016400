.block--ng-shop-shop-filter .block__title {
  display: block;
}

.block--ng-shop-shop-filter .views-exposed-widget {
  @include mq($desk, true) {
    @include rhythm(0, 0, 0, 1);
  }
}

.block--ng-shop-shop-filter .views-exposed-widget > label {
  border-bottom: solid 1px $light;
  display: block;
  @include font-size(16px);
  @include rhythm(0, 0, 0, 0.5);
  font-weight: normal;
  padding-left: 0;
  &[for="edit-allergy"] {
    @include icon(inject);
  }
  &[for="edit-ingredients"] {
    @include icon(plus-circle);
  }
  &[for="edit-without-ingredients"] {
    @include icon(minus-circle);
  }
  &[for="edit-marke"] {
    @include icon(tags);
  }
  &[for="edit-complexity"] {
    @include icon(hat);
  }
  &:before {
    padding-right: rhythm(0.5);
  }
}

// Style the checkbox active behaviour on desktop.
.block--ng-shop-shop-filter .checkbox label {
  display: block;
  cursor: pointer;
}

.block--ng-shop-shop-filter .checkbox input {
  float: left;
  position: relative;
  top: 4px;
  margin-right: 8px;
}

.block--ng-shop-shop-filter .checkbox.active {
  @include mq($desk, true) {
    border-left: 3px solid $green;
    background-color: $light;
    color: $grey--dark;
  }
}

// Filter in sidebar and featured scrollbar.
.block--ng-shop-shop-filter #reset-form.form-submit {
  @include icon(close, after) {
    position: relative;
    top: 1px;
  }
}

// Hide the submit button on desktop mode.
.block--ng-shop-shop-filter .close-off-canvas {
  display: none;
}

// Off canvas shop filters.
.region--off-canvas .block--ng-shop-shop-filter .block__title {
  display: none;
}

.region--off-canvas .block--ng-shop-shop-filter .checkbox {
  border-left: 3px solid transparent;
  cursor: pointer;
  margin-bottom: 1px;
  padding-left: 1em;
}

// Legacy cause drupal sucks sometimes.
.block--ng-shop-shop-filter--2 {
  @extend .block--ng-shop-shop-filter;
}
