@use "sass:math";

@import 'libraries/semantic-ui-transition/transition.min';
@import 'libraries/semantic-ui-sticky/sticky.min';
@import 'libraries/semantic-ui-menu/menu.min';
@import 'libraries/semantic-ui-popup/popup.min';
@import 'libraries/semantic-ui-sidebar/sidebar.min';
@import 'libraries/semantic-ui-accordion/accordion.min';
@import 'libraries/semantic-ui-checkbox/checkbox.min';
@import 'libraries/semantic-ui-dropdown/dropdown.min';
@import 'libraries/semantic-ui-tab/tab.min';
@import "dependencies";

/**++ SEEM KRAM MUSS NOCH AUSGELAGERT WERDEN ++**/
@function column-width($span: $columns of $container-columns, $display: block) {
  $columns: nth($span, 1);
  $container-columns: container-span($span);
  $display-table: is-display-table($container-display-table, $display);

  @if $display-table {
    @return percentage(math.div($columns, $container-columns));
  } @else {
    @return flex-grid($columns, $container-columns);
  }
}

.menu {
  @include clearfix;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.menu li a {
  display: block;
}

.block--menu .block__title {
  display: none;
}

#admin-menu,
#environment-indicator {
  display: none;
  @include mq($tab, true) {
    display: block;
  }
}

#l10n-client {
  display: none;
  @include mq($desk, true) {
    display: block;
  }
}

#toolbar {
  display: none;
  @include mq($desk, true) {
    display: block;
  }
}

/**++ SEEM ENDE ++**/

body.logged-in.toolbar {
  padding-top: 0 !important;
  @include mq($desk, true) {
    padding-top: 52px !important;
  }
}

// only on frontpage

.block--sb-shop-shop-region-menu .block__title {
  display: none;
}

.container {
  @include outer-container;
}

.ui.sticky {
  text-align: center;
  @include mq($desk, true) {
    text-align: left;
  }
}

.ui.sticky .site-logo {
  @include mq($desk, true) {
    opacity: 0;
  }
  cursor: default;
  @include transition(opacity 0.3s linear);
}

.ui.sticky.fixed {
  width: 100%;
  position: static;
  //margin-top: 0px !important;
  .site-logo {
    cursor: pointer;
    opacity: 1;
  }
  & > .sticky-menu--wrapper {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
  }
}

.sticky .site-logo {
  background-color: $green;
  display: block;
  float: left;
  padding-top: 5px;
  padding-left: 8px;
  @include mq($tab) {
    padding-top: 6px;
  }
  @include mq($desk, true) {
    padding-top: 0;
    padding-bottom: 0;
  }
  img {
    //float: none;
    display: inline-block;
    width: 140px;
    //margin-left: -60px;
    //margin-top: 4px;
    @include mq($desk, true) {
      display: block;
      float: left;
      width: 200px;
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.sticky,
.sticky.fixed > .sticky-menu--wrapper {
  //border-top: solid 4px #b6c62e;
  //border-top: solid 4px #57ab55;
  background-color: #57ab55;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  height: 43px;
  //@include mq($tab) {
  //  height: 47px;
  //}
  @include mq($desk, true) {
    border-top: solid 4px #57ab55;
    height: 51px;
    background-color: #b6c62e;
    //background-color: #333;
    border-top: solid 4px #57ab55;
  }
  @include mq($desk, true) {
  }
}

.sticky .sticky-menu--wrapper > .menu {
  @include mq($desk, true) {
    @include outer-container;
  }
  position: relative;
}

.ui.left.sidebar, .ui.right.sidebar {
  width: 300px;
}

.l-front--push-container {
  background-color: #b6c62e;
  border-top: solid 5px #fff;
  @include mq($tab, true) {
    padding: rhythm(4);
  }
}

// TODO: This should mire be an asset.
// It is used on the frontpage, landigpage shop and recipe, thematic retailing
// for recipe teasers and for the teasers in recipe nodes.
//.l-main--content_footer {
//  .pane-title {
//    @include rhythm(4, 4);
//  }
//  .image-teasers {
//    @include rhythm(4, 4, 4, 4);
//  }
//}

body {
  width: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
}


a.btn {
  display: block;
  //padding: 0.5em 1em 0.5em;
  @include mq($tab, true) {
    //padding: 0.15em 1em 0.15em;
    //margin-top: 1em;
    display: inline-block;
  }
  margin-bottom: rhythm(0.5);
}

.field--node-link a {
  @extend %button;
  margin-top: rhythm(1);
}

.remark {
  color: #fff;
  line-height: 1em;
  padding: 1em;
  @include mq($desk, true) {
    color: inherit;
    padding: 0;
  }
}




.off-canvas--cart {

}

//
//.shopping-cart-wrapper .view + div {
//  @extend .remark;
//}
//
//.shopping-cart-wrapper table {
//  border: none;
//  margin: 0;
//}
//
//.shopping-cart-wrapper table tr:nth-child(2n),
//.shopping-cart-wrapper table tr {
//  border-top: solid 1px #555;
//  border-bottom: solid 1px #333;
//  background-color: #444;
//  display: block;
//  color: #fff;
//  padding: 10px 1em 9px;
//  @include mq($desk, true) {
//    background: transparent;
//    border-top: none;
//    color: #333;
//  }
//}
//
//.shopping-cart-wrapper table tr:first-child {
//  border-top: none;
//}
//
//.shopping-cart-wrapper table td.views-field-quantity {
//  width: 5%;
//}
//
//.shopping-cart-wrapper table td.cart-product-image {
//  width: 10%;
//  img {
//    border: solid 1px #999;
//  }
//}
//
//.shopping-cart-wrapper table td.product-title {
//  width: 100%;
//  padding-left: 9px;
//}
//
//.shopping-cart-wrapper table td.price {
//  text-align: right;
//  width: 10%;
//}
//
//.shopping-cart-wrapper table tbody tr td a.active,
//.shopping-cart-wrapper table tbody tr td a {
//  color: #fff;
//  @include mq($desk, true) {
//    color: #333;
//  }
//}
//
//.shopping-cart-wrapper table td {
//  border: none;
//  padding: 0;
//}
//
//.view-footer .links {
//  border-top: solid 1px #333;
//  margin-top: 9px;
//  margin-right: -1em;
//  margin-left: -1em;
//  margin-bottom: 0;
//  .off-canvas__content & {
//    margin: 0;
//  }
//}
//
//.view-footer .links li a {
//  @extend %button;
//  border-top: solid 1px #555;
//  border-bottom: solid 1px #111;
//  display: block;
//  text-align: center;
//  height: 42px;
//  line-height: 42px;
//  padding: 0;
//  margin: 0;
//  &:after {
//    display: none;
//  }
//}
//
//.view-footer .links li:first-child a {
//  border-bottom: solid 1px #008303;
//}
//
//.view-footer .links li:last-child a {
//  border-top: solid 1px #50d062;
//}
//
//.view-footer .links {
//  list-style: none;
//  padding: 0;
//}
//
//.search-block-form .form-submit {
//  @extend %button;
//
//}
//
//.view-shopping-cart-custom .view-empty {
//  padding: 10px 1em 9px;
//}







#loader {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10;
}

.field--node-link a, .field--node-link a:visited {
  @extend %button;
}

.form-submit {
  @extend %button;
}

.file-document {
  @include icon(download, before, 36px);
  &:before {
    color: $green;
    margin-right: rhythm(1);
    position: relative;
    bottom: -12px;
  }
  .file-icon {
    display: none;
  }
  a {
    @extend %button;
    @include icon(none, after);
  }
}

.add-to-cart-msg {
  background-color: #b6c62e;
  box-shadow: 1px 4px 4px rgba(29, 29, 27, 0.25);
  padding: rhythm(1);
  position: fixed;
  z-index: 999;
  top: 30%;
  h3 {
    @include font-size(16px);
    color: white;
    a:hover {
      color: #d75d30;
    }
  }
  a {
    border-bottom: solid 1px #fff;
  }
}
// Beschreibung
// Zutaten
// Nährwerte
// Gut zu wissen

// Rezept in mehreren Kategorien
// Startseite am stück

// Adpush - zum Rezept / zum Shop



.inline {
  display: inline;
}




.region--user-sidebar {
  display: none;
  @include mq($desk, true) {
    @include span-columns(3);
}
}
.page-user .region--user-content{
  @include mq($desk, true) {
  @include span-columns(12);
  }

}
.region--user-content h2,
.user-page__title {
  background-color: $green2;
  clear: both;
  color: #fff;
  padding: rhythm(1) ($gutter*0.5);
  margin-bottom: rhythm(1);
  @include mq($desk, true) {
    margin-bottom: rhythm(1);
  }
}

.confirm-parent, .password-parent {
  clear: left;
  margin: 0;
  width: 100%;
}
div.password-confirm {
  text-align: right;
  margin-top: 0;
}
.password-indicator {
  background-color: $light;
}
.password-parent .password-strength {
  margin-top: -7px;
}
.password-indicator div {
  background-color: $green2;
}
div.form-item div.password-suggestions {
  padding: rhythm(1) $gutter;
  margin: rhythm(0.5) 0 rhythm(1);
  border: 1px solid $green2;
}

div.error, .error {
  color: $orange;
}
