.slide__title {
  font-family: $open-sans;
  background-color: rgba(255,255,255,0.7);
  padding: rhythm(0.25) ($gutter*0.5) rhythm(0.75);
  clear: both;
  display: block;
  text-align: center;
  a {
    color: #222;
  }
  @include mq($tab, true) {
    padding: rhythm(2) ($gutter*2);
    position: absolute;
    top: 50px;
  }
}
