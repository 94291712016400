.block--ng-shop-recipe-filter {
  @extend .block--ng-shop-shop-filter;
}

.block--ng-shop-recipe-filter .form-type-bef-checkbox {
  clear: both;
}

.block--ng-shop-recipe-filter label {
  display: inline;
}

.block--ng-shop-recipe-filter .hat--icons {
  float: right;
}

.block--ng-shop-recipe-filter .icon--hat {
  @include icon(hat, before);
  color: #888;
}

.icon--hat:nth-child(1) {
  .recipe-complexity-0 &,
  .recipe-complexity-1 &,
  .recipe-complexity-2 & {
    color: #fff;
    @include mq($desk, true) {
      color: #000;
    }
  }
}

.icon--hat:nth-child(2) {
  .recipe-complexity-1 &,
  .recipe-complexity-2 & {
    color: #fff;
    @include mq($desk, true) {
      color: #000;
    }
  }
}

.icon--hat:nth-child(3) {
  .recipe-complexity-2 & {
    color: #fff;
    @include mq($desk, true) {
      color: #000;
    }
  }
}

// Legacy cause drupal sucks sometimes.
.block--ng-shop-recipe-filter--2 {
  @extend .block--ng-shop-recipe-filter;
}
