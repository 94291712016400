.block--sb-base-sb-trusted-shop {
  text-align: center;
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(3);
    float: right;
    @include omega;
    margin-bottom: 0;
  }
  @include mq($desk, true) {
    @include span-columns(4);
    float: right;
  }
}

.block--sb-base-sb-trusted-shop .block__title {
  display: none;
}

.block--sb-base-sb-trusted-shop .block__content {
  display: inline-block;
}

.block--sb-base-sb-trusted-shop .img {
  float: none;
}
