.page-header-video {
  @include clearfix;
  position: relative;
  //@include outer-container($mobile-padding: 0);
  @include mq($tab, true) {
    margin-bottom: rhythm(2);
  }
}

.field--page-header-video {
  @include outer-container($mobile-padding: 0);
  position: relative;

  .player {
    padding-bottom:56.25%;
    position:relative;
    display:block;
    width: 100%;

    iframe {
      position:absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
