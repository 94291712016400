.view-sb-shopping-cart-summary {
  @extend .view-commerce-cart-form
}

// Some small adjustments for the shopping cart summary.
.view-sb-shopping-cart-summary {
  .views-field-commerce-total {
    height: rhythm(1);
    padding: 0 8px 0 0;
  }
}

.commerce-order-handler-area-order-total {
  tbody tr {
    padding: rhythm(0.5) 0;
  }
  .component-title {
    float: left;
  }
  .component-total {
    float: right;
  }
  .commerce-price-formatted-components {
    width: auto;
  }
}
