// Ui checkbox override styles to fit the ci styles.
.ui.checkbox {
  display: block;
  border-bottom: solid 1px #444;
  padding: 6px 1em;
}

.ui.checkbox:last-child {
  border-bottom: none;
}

.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui) .title,
.ui.accordion:not(.styled) .title ~ .content:not(.ui) .title {
  padding: 0 0 0 rhythm(3);
}

.ui.toggle.checkbox .box, .ui.toggle.checkbox label,
.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: #fff !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: $green !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: rgba(255, 255, 255, 0.95) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: $green !important;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before,
.ui.toggle.checkbox label:hover::before,
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before,
.ui.checkbox .box:before, .ui.checkbox label:before {
  width: 3rem;
  height: 1.5rem;
}

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  height: 1.5rem;
  width: 1.5rem;
}

.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 1.5rem;
}
