// @todo: Create mobile style for gallery image.
.node-type-page .paragraphs-items-full .page-header-image-gallery:first-child {
  //margin-top: -(rhythm(3));
}
.page-header-image-gallery {
  @include clearfix;
  position: relative;
  //@include outer-container($mobile-padding: 0);
  @include mq($tab, true) {
    margin-bottom: rhythm(2);
  }
}

.field--page-header-image-wrapper {
  @include outer-container($mobile-padding: 0);
  position: relative;
}

.field--page-header-image {
  @include clearfix;
}

.page-header-image-gallery .group-overlay {
  clear: both;
  position: relative;
  @include mq($tab, true) {
    top: -(rhythm(2));
    margin-bottom: -(rhythm(2));
    position: absolute;
    max-width: 50%;
    top: rhythm(2);
    left: 4rem;
  }
}

.field--page-header-background {
  overflow: hidden;
  position: absolute;
  height: 406px;
  width: 100%;
  top: 0px;
  img {
    width: 100%;
    -webkit-filter: blur(30px);
    opacity: 0.8;
  }
}

.field--page-header-image img {
  width: 100%;
  @include mq($desk, true) {
    width: auto;
  }
}

.page-header-image-gallery.no-link .group-overlay {
  .field--page-header-title {
    //background-color: $transgreen;
    color: #fff;
    //margin-bottom: 0;
    //padding: rhythm(0.5);
    //@include mq($tab, true) {
    //  background-color: $green2;
    //  padding: rhythm(1);
    //}
    background-color: $green2;
    box-shadow: rhythm(0.5) 0 0 $green2, -(rhythm(0.5)) 0 0 $green2;
    display: block;
    font-family: $open-sans;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    /* margin-right: -6rem; */
    padding: rhythm(0.5) $gutter*0.25;
    @include font-size(26px);
    letter-spacing: 2px;
    line-height: 34px;
    @include mq($tab, true) {
      display: inline;
      text-align: left;
      line-height: 60px;
      padding-right: 0;
      padding-left: 0;
    }
    @include mq($desk, true) {
      @include font-size(60px);
      line-height: 111px;
    }
  }
  //.field--page-header-description {
  //  background-color: rgba(255, 255, 255, 0.75);
  //  padding: rhythm(0.5);
  //  @include mq($tab, true) {
  //    padding: rhythm(1);
  //  }
  //}
}

.page-header-image-gallery.has-link .group-overlay {
  background-color: rgba(255, 255, 255, 0.75);
  padding: rhythm(0.5);
  @include mq($tab, true) {
    max-width: 40%;
    padding: rhythm(1);
  }
}

.field--page-header-title {
  @extend h1;
}

.field--page-header-description {
  color: $dark;
  @include font-size(16px);
}

.field--page-header-link a {
  background-color: $green2;
  color: #fff;
  display: inline-block;
  padding: rhythm(0.25) rhythm(0.5);
  margin-top: rhythm(0.5);
  @include icon(arrow-right, after, 10px) {
    margin-left: rhythm(0.25);
  }
  &:hover {
    background-color: $green;
    color: #fff;
  }
}

.page-header-image-gallery--toc {
  @include mq($tab, true) {
    width: 250px;
    position: absolute;
    top: -(rhythm(1));
    right: 0;
  }
  h5 {
    background-color: $green2;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    padding: rhythm(0.5);
    @include icon(arrow-right, after) {
      float: right;
      display: inline-block;
      transform: rotate(90deg);
    }
  }
  h5.is-open:after {
    transform: rotate(-90deg);
  }
  .item-list {
    background-color: rgba(255, 255, 255, 0.75);
    display: none;
  }
  .item-list.is-open {
    display: block;
  }
  .item-list ul {
    list-style: none;
    margin: 0;
    padding: rhythm(0.5);
  }
  .item-list a {
    color: $orange;
    &:hover {
      color: $dark;
    }
  }
}
