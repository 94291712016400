.pages-ad-pushes {
  background-color: #edf1c4;
  padding: rhythm(0.5) $gutter*0.5;
  @include mq($tab, true) {
    padding: 0 0 rhythm(1.5);
    margin: 0 0 rhythm(1.5);
  }
}

.pages-ad-pushes > .field--title-field {
  background: transparent;
  color: #000;
  font-weight: normal;
  text-align: center;
  margin-bottom: rhythm(0.5);
  @include mq($tab, true) {
    margin-bottom: rhythm(1.5);
    padding-top: rhythm(1.5);
  }
}

.pages-ad-pushes > .field--title-field,
.pages-ad-pushes .flexslider {
  @include mq($tab, true) {
    @include outer-container;
  }
}

.page-push--image-teaser {
  background-color: #fff;
  min-height: 471px;
  @include content-icons('.field--title-field h2', 24px) {
    background-color: $green2;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    height: 42px;
    width: 42px;
    text-align: center;
    margin-right: rhythm(1);
  }
  @include mq($tab, true) {
    border: solid rhythm(1) rgba(255, 255, 255, 1);
  }
  cursor: pointer;
  img {
    transition: all .2s ease-in-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
  .recipe__content,
  .field--ad-push-image {
    @include clearfix;
    overflow: hidden;
  }
}

.page-push--image-teaser .field--title-field h2 {
  background-color: rgba(255, 255, 255, 0.75);
  margin-top: rhythm(0.5);
  margin-bottom: rhythm(0.5);
  max-width: 300px;
  padding: rhythm(0.5) rhythm(1);
  position: absolute;
  top: rhythm(2);
  z-index: 10;
  a {
    color: inherit;
  }
}

.field--ad-push-description {
  clear: both;
  padding: rhythm(1);
  @include mq($tab, true) {
    padding: rhythm(1) 0 0;
  }
}

//.page-push--image-teaser,
.push--image-teaser {
  //  cursor: pointer;
  //  overflow: hidden;
  //  position: relative;
}

//
//.page-push--image-teaser:after,
//.push--image-teaser:after {
//  border: solid 20px rgba(255, 255, 255, 0.9);
//  box-sizing: border-box;
//  display: block;
//  content: '';
//  height: 100%;
//  width: 100%;
//  position: absolute;
//  top: 0;
//  z-index: 2;
//}
//
//.field--push-image {
//  position: relative;
//  z-index: 0;
//}
//
//.field--push-image img {
//  height: inherit;
//  width: 100%;
//  vertical-align: middle;
//}
//
//.page-push--image-teaser .content--group,
//.push--image-teaser header {
//  position: absolute;
//  width: 100%;
//  left: 0;
//  bottom: 0;
//  z-index: 3;
//  h2,
//  h2 a,
//  h2 a:visited {
//    color: white;
//  }
//}
//
//.page-push--image-teaser .content--group h2,
//.push--image-teaser header h2 {
//  background-color: rgba(195, 212, 48, 0.9);
//  margin: 20px;
//  padding: 20px;
//}
//
//.field--push-link-field {
//  display: none;
//}

