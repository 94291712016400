// Checkout progess indication.
@use "sass:math";

$mobile-task-label-height: 4rem;

.block--commerce-checkout-progress-indication {
  margin-bottom: rhythm(0.5);
  margin-top: rhythm(0.5);
  position: relative;
  padding-bottom: $mobile-task-label-height;

  @include mq($tab, true) {
    margin-top: 0;
    padding-bottom: 0;
  }

  @include mq($desk, true) {
    margin-bottom: rhythm(2);
  }
}

.commerce-checkout-progress {
  margin: 0;
}

.inline.commerce-checkout-progress li {
  list-style: none;
  width: percentage(math.div(1, 6));
  float: left;
  @include mq($tab, true) {
    position: relative;
  }

  &.active>a,
  &.active>span {
    border-color: $green2;
    &:hover {
      border-color: $green2;
    }
  }
  &:last-child>span,
  &:last-child>a {
    margin-bottom: 0;
  }
}

.inline.commerce-checkout-progress li>span,
.inline.commerce-checkout-progress li>a {
  border: solid 1px $light;
  border-bottom-width: 6px;
  background: none;
  display: block;
  color: inherit;
  padding: rhythm(0.25) 0;
  text-align: center;
  @include transition(all 0.3s ease-in-out);
  @extend h2;
  @include font-size(18px);
  margin-bottom: 0;

  @include mq($tab, true) {
    height: rhythm(8);
    text-align: center;
    margin-bottom: 0;
    position: relative;
    padding: rhythm(0.5) ($gutter*0.5);
  }
  @include mq($desk, true) {
    height: rhythm(9);
    padding: rhythm(1) $gutter;
  }

  .li-title {
    display: none;

    @include mq($tab, true) {
      display: block;
    }
  }
}
@media screen and (max-width: 767px) {
  .inline.commerce-checkout-progress li.active {
    .li-title {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $green2;
      width: 100%;
      height: $mobile-task-label-height;
      text-align: left;
      color: #fff;
      @include font-size(26px);
      line-height: 54px;
      font-family: $open-sans;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-left: 0.5em;

      @include mq($tab, true) {
        position: static;
        background: none;
      }
    }
  }
}

.inline.commerce-checkout-progress li>a {
  &:hover {
    border-color: $green2;
    background: none;
    &:after {
      border-color: $green2;
    }
  }
}

.inline.commerce-checkout-progress li:after,
.inline.commerce-checkout-progress li.active:before,
.inline.commerce-checkout-progress li.active:after {
  @include mq($desk, true) {
    background: #fff;
    border-top: solid 1px $light;
    border-right: solid 1px $light;
    content: '';
    display: block;
    height: 34px;
    width: 34px;
    position: absolute;
    z-index: 10;
    @include transform(rotate(45deg));
    top: rhythm(2);
  }
}

.inline.commerce-checkout-progress li.active:before,
.inline.commerce-checkout-progress li.active:after {
  border-color: $green2;
}

.inline.commerce-checkout-progress li.active:after,
.inline.commerce-checkout-progress li:after {
  @include mq($desk, true) {
    right: -17px;
  }
}

.inline.commerce-checkout-progress li.active:before {
  @include mq($desk, true) {
    left: -16px;
  }
}

.inline.commerce-checkout-progress li:last-child:after {
  display: none;
}

.inline.commerce-checkout-progress li>a:before,
.inline.commerce-checkout-progress li>span:before {
  background-color: $light;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  text-align: center;
  width: 35px;
  height: 35px;
  margin: 0 0 0 0;
  padding: 6px 0 0;
  position: relative;
  z-index: 11;
  @include mq($tab, true) {
    display: block;
    width: 75px;
    height: 75px;
    margin: 0 auto rhythm(1);
    padding: 15px 0 0;
  }
}

.inline.commerce-checkout-progress li.visited>a:before,
.inline.commerce-checkout-progress li.visited>span:before {
  background-color: $green2;
}

.inline.commerce-checkout-progress li.active>a:before,
.inline.commerce-checkout-progress li.active>span:before {
  background-color: $green2;
}

.inline.commerce-checkout-progress li.login>a,
.inline.commerce-checkout-progress li.login>span {
  @include icon(key) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.inline.commerce-checkout-progress li.checkout>a,
.inline.commerce-checkout-progress li.checkout>span {
  @include icon(bag) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.inline.commerce-checkout-progress li.address_data>a,
.inline.commerce-checkout-progress li.address_data>span {
  @include icon(address-book) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.inline.commerce-checkout-progress li.shipping>a,
.inline.commerce-checkout-progress li.shipping>span {
  @include icon(credit) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.inline.commerce-checkout-progress li.review>a,
.inline.commerce-checkout-progress li.review>span {
  @include icon(cart-checkout) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.inline.commerce-checkout-progress li.complete>a,
.inline.commerce-checkout-progress li.complete>span {
  @include icon(checkmark) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}
