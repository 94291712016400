.recipe--image-teaser {
  @extend .page-push--image-teaser;
  border: solid rhythm(1) rgba(255, 255, 255, 1);
  @include clearfix;
  position: relative;
  &.no-link {
    cursor: default;
    &:hover img {
      transform: scale(1);
    }
  }
}

.recipe.recipe--image-teaser {
  min-height: inherit;
}
