.field--page-body {
  .teaser {
    @include font-size(20px);
    font-weight: bold;
    margin-bottom: rhythm(1);
  }
  .quotation {
    border-left: solid 8px $green2;
    @include font-size(16px);
    font-style: italic;
    margin-bottom: rhythm(1);
    padding: rhythm(1);
  }

  .call-to-action {
    background-color: $green2;
    box-shadow: $gutter*0.5 0 0 #b6c62e, -($gutter*0.5) 0 0 #b6c62e;
    display: inline-block;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: $open-sans;
    font-weight: bold;
    margin-right: $gutter;
    margin-bottom: 5px;
    position: relative;
    left: 0.84375em;
    a {
      color: #fff;
      text-decoration: none;
    }
    &:hover {
      animation: call-to-action 1s ease-in-out;
    }
  }

  p {
    @include font-size(16px);
  }

  h2 {
    @include font-size(28px);
  }
  h3 {
    @include font-size(24px);
  }
  h4 {
    @include font-size(20px);
  }
  h2, h3, h4 {
    color: #222;
    font-family: $pt-sans;
    line-height: 1.5;
    margin: 0;
  }

  p + ul,
  h2 + p,
  h3 + p,
  h4 + p,
  ul + p,
  p + p {
    margin-top: 0;
    margin-bottom: rhythm(1);
  }
}

@keyframes call-to-action {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(3deg);
  }
  20% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(3deg);
  }
  40% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(0);
  }
  70% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(0);
  }
  90% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0);
  }
}
