// Even if this region is called "shop-header", it's used for both, shop and recipe landingpage since they both use
// the same technics and blocks.
.region--shop-header {
  border-bottom: solid 1px #eee;
  padding-bottom: rhythm(1);
  margin-bottom: rhythm(1);
}

// We use this paragraph only for the sticky menu and off-canvas.
.region--shop-header p {
  display: none;
}

.region--shop-header .block__title {
  display: none;
}

// The category dropdown.
.region--shop-header .block--ng-shop-shop-categories {
  float: left;
  width: 32%;
  display: none;
  @include mq($tab, true) {
    display: block;
  }
  .selectize-control.single .selectize-input, .selectize-dropdown.single {
    border-color: $green2;
  }

  .selectize-control.single .selectize-input {
    background: $green2;
    color: #fff;
    @include font-size(16px);
    font-weight: bold;
    border-radius: 0;
    box-shadow: none;
    height: rhythm(2);
    line-height: rhythm(2);
    display: block;
    padding: 0 12px;
    input {
      width: 100% !important;
    }
  }

  .selectize-dropdown .active {
    background: none;
    font-weight: bold;
  }

  .selectize-control.single .selectize-input:after {
    border-color: #fff transparent transparent transparent;
  }

  .selectize-input.selectize-focus {
    border: solid 1px $green !important;
  }
}

// The search form.
.region--shop-header [name="sb-recipe-search"],
.region--shop-header [name="sb-shop-search"] {
  position: relative;
  @include mq($tab, true) {
    float: left;
    width: 68%;
  }

  input[type="search"] {
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: rhythm(2);
    color: #333;
    text-align: center;
    line-height: rhythm(2);
    padding: 0 12px;
    margin: 0;
    &:focus {
      outline: none;
      border-color: $green;
    }
    @include mq($tab, true) {
      text-align: left;
    }
  }

  [type="submit"] {
    background: none;
    border: none;
    @include icon(search);
    position: absolute;
    right: 12px;
    top: 9px;
    span {
      display: none;
    }
  }
}

// The aggregated result summary area.
.shop-search--result-summary {
  background-color: #fefff0;
  clear: both;
  margin-top: rhythm(1);
  padding: rhythm(1);
  @include mq($tab, true) {
    background-color: transparent;
    margin-top: 0;
    padding: rhythm(1) 0 0;
  }
}

// The toggle buttons on tablet and mobile to show the off-canvas sidebar.
.toggle-categories,
.toggle-product-filter,
.toggle-remove-filters {
  border: 1px solid #e6e6e6;
  border-radius: 0.25em;
  display: inline-block;
  width: 49%;
  height: rhythm(2);
  color: #333;
  text-align: center;
  line-height: rhythm(2);
  margin: rhythm(0.5) 0 0;
  @include mq($desk, true) {
    display: none;
  }

  .indicator {
    display: inline-block;
    margin-left: 0.5em;
    border-radius: 1em;
    background: $green;
    color: #fff;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.6em;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
  }
}

.toggle-categories {
  @include icon(tags) {
    position: relative;
    top: 1px;
    margin-right: 6px;
  }
}

.toggle-recipe-filter,
.toggle-product-filter {
  @include icon(inject) {
    position: relative;
    top: 1px;
    margin-right: 6px;
  }
  float: right;
}

.toggle-remove-filters {
  width: 100%;

  @include icon(close, after) {
    position: relative;
    top: 1px;
    margin-left: 6px;
  }
}

form[name="sb-recipe-search"], form[name="sb-shop-search"] {
  @media screen and (max-width: 767px) {
    margin-top: 0.75rem;
  }
}
