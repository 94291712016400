.form-type-textfield,
.form-type-radios,
.form-type-iban,
.form-type-bic,
.form-type-german-bank-account-number,
.form-type-german-bank-code,
.form-type-password,
.form-type-password-confirm,
.form-type-select,
.form-type-textarea {
  & > label {
    display: block;
    line-height: rhythm(1);
    margin: rhythm(0.25) 0;
  }
  select,
  input[type="text"],
  input[type="password"] {
    padding: 0 1em;
    border: 1px solid #e6e6e6;
    display: inline-block;
    width: 100%;
    height: rhythm(2);
    color: #333;
    line-height: rhythm(2);
    margin: 0 0 rhythm(0.5);
    &:focus {
      border: solid 1px $green;
      outline: none;
    }
    &.error {
      border-color: $orange;
    }
  }
  textarea {
    border: 1px solid #e6e6e6;
    &:focus {
      border: solid 1px $green;
      outline: none;
    }
    &.error {
      border-color: $orange;
    }
  }
  .form-type-radio {
    margin: 0 0 rhythm(0.5);
  }
  select {
    border-radius: 0;
  }
  .description {
    clear: both;
    //text-align: right;
    @include font-size(12px);
    margin-bottom: rhythm(1);
    //font-style: italic;
  }
}

select,
input[type="email"],
input[type="text"],
input[type="password"] {
  max-width: 100%;
}

.form-actions button + a,
.form-actions button + button {
  margin-top: rhythm(0.5);
  @include mq($desk, true) {
    margin-top: inherit;
    margin-left: ($gutter*0.5);
  }
}

.form-type-radios {
  margin: 0 0 rhythm(1);
}

.form-type-item,
.fivestar-form-item {
  margin: 0 0 rhythm(0.5);
}

.form-type-fivestar {
  label {
    float: left;
    margin-right: ($gutter*0.5);
  }
  .form-type-select {
    position: relative;
    top: 2px;
  }
}

.webform-component-textarea {
  clear: both;
  margin: 0 0 rhythm(0.5);
  textarea {
    border: 1px solid #e6e6e6;
    &:focus {
      border: solid 1px $green;
      outline: none;
    }
  }
}

.webform-component-email,
.webform-component-textfield,
.webform-component-select {
  @include mq($tab, true) {
    @include span-columns(4 of 8);
    @include omega(2n);
  }
  select,
  input[type="email"],
  input[type="text"] {
    padding: 0 1em;
    border: 1px solid #e6e6e6;
    display: inline-block;
    height: rhythm(2);
    color: #333;
    width: 100%;
    line-height: rhythm(2);
    margin: 0 0 rhythm(0.5);
    &:focus {
      border: solid 1px $green;
      outline: none;
    }
  }
}

.webform-component-textarea,
.webform-component-email,
.webform-component-textfield,
.webform-component-select {
  label {
    display: block;
    line-height: rhythm(1);
    margin: rhythm(0.25) 0;
  }
}