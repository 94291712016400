.initiative-application {
  .formular__title {
    color: $dark;
    background-color: inherit;
    margin-bottom: 1rem;
  }

  .formular--full .webform-client-form {
    margin-top: 2em;
  }

  form label {
    font-weight: bold;
  }

  form .form-type-radio label {
    font-weight: normal;
  }

  .form-radios {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;;
  }

  .webform-component-textfield,
  .webform-component-email {
    float: none;
    width: 100%;
    margin: 0;
  }

  .form-item.webform-component {
    margin-bottom: 1em;
  }

  .form-submit {
    color: $light;
    background-color: $green2;
  }
}
