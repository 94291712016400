.event--teaser {
  @extend .news--teaser;
}

.not-front .event--teaser .event__title {
  @include mq($desk, true) {
    margin-top: rhythm(1);
  }
}

.event--teaser .event__content {
  @include mq($tab, true) {
    @include span-columns(9.5);
    @include shift(0.5);
    @include omega;
  }
}

.event--teaser .inline {
  display: block;
}

.event--teaser .field--event-body {
  margin-bottom: rhythm(1);
}
.event--teaser .field-label {
  font-weight: bold;
  float: left;
  margin-right: 4px;
}

.field--event-date + .field-label {
  clear: left;
}
