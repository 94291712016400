.block--menu-menu-sb-footer-menu {
  background-color: $green;
  margin-right: -($gutter * 0.5);
  margin-left: -($gutter * 0.5);
  margin-bottom: rhythm(1);
  padding-bottom: 4px;
  @include mq($tab) {
    text-align: right;
    margin-right: -($gutter);
    margin-left: -($gutter);
    padding-right: $gutter;
  }
  @include mq($desk-plus, true) {
    float: right;
    margin: -(rhythm(2)) 0 0;
    padding-bottom: 0;
    padding-right: 0;
    position: relative;
    top: -30px;
  }
}

.block--menu-menu-sb-footer-menu .menu--item {
  display: block;
  text-align: center;
  @include mq($tab, true) {
    display: inline-block;
  }
}

.block--menu-menu-sb-footer-menu .menu--item__link {
  color: #fff;
  padding: 5px ($gutter * 0.5) 4px;
  &:hover {
    background-color: $green2;
    color: #fff;
  }
  &.active {
    color: #fff;
    font-weight: bold;
  }
}
