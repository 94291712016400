.block--menu-menu-fat-footer-1 {
  display: none;
  @include mq($tab, true) {
    display: block;
    @include span-columns(3);
  }
}

.block--menu-menu-fat-footer-1.block--menu .block__title {
  display: block;
  color: #fff;
}

.block--menu-menu-fat-footer-1 .menu--item {
  display: block;
}

.block--menu-menu-fat-footer-1 .menu--item__link {
  color: $green;
  &:hover {
    color: $green2;
  }
}
