.region--sidebar {
  border: solid rhythm(0.5) #eeeeee;
  padding: rhythm(0.5);
  margin: {
    right: -(rhythm(0.5));
    left: -(rhythm(0.5));
    bottom: -(rhythm(0.5));
  }
  @include mq($tab, true) {
    border-width: 20px;
    padding: rhythm(1) $gutter;
    margin: 0;
  }
  @include mq($desk, true) {
    @include span-columns(4);
    margin-left: -100%;
  }
  &.not-mobile {
    display: none;
    @include mq($desk, true) {
      display: block;
    }
  }
  &.sidebar-right {
    @include mq($tab) {
      margin-bottom: rhythm(2);
    }
    @include mq($desk, true) {
      @include shift(0);
      @include omega;
      float: right;
      margin-bottom: 0;
    }
  }
}

.region--sidebar {
  .webform-component-email, .webform-component-textfield, .webform-component-select,
  .webform-component-email:nth-child(2n+1), .webform-component-textfield:nth-child(2n+1), .webform-component-select:nth-child(2n+1) {
    width: 100%;
  }
}
