/**
 * This is currently not used so we decided to not entirely port this to the new theme. Still we did some
 * transformations in order to make the sass compile and not loose every code, in case this feature will return some
 * day.
 */

// Inhaltsvereichnis
.index-of-contents--wrapper {
  //@extend %container;
  position: relative;
}
.index-of-contents {
  @include span-columns(6);
  background-color: $transwhite;
  position: absolute;
  top: -11px;
  right: 0;
  z-index: 9;
  h3 {
    background-color: $green2;
    color: white;
    @include font-size(18px);
    padding: 4px 11px 3px;
    // &:after {
    //   content: '\25BC';
    //   @include adjust-font-size-to(9px);
    //   margin-left: rhythm(2);
    // }
  }
  nav {
    padding: rhythm(1) 0;
  }
  a {
    display: block;
    padding: 0 11px;
  }
}
//
//.field--thematic-section-products:nth-child(2) {
//  @include span-columns(6);
//}
//.field--thematic-section-products:nth-child(3) {
//  @include span-columns(6);
//}
//.field--thematic-section-products:nth-child(4) {
//  @include span-columns(6);
//}
//.field--thematic-section-products:nth-child(5) {
//  @include span-columns(6);
//}

.pane-thematic-retailing-overview-thematic-retailing-overview-pane,
.field-thematic-retailing-section {
  @include rhythm(1, 1, 1, 1);
  clear: both;
  @include clearfix;
}

.node-type-thematic-retailing {
  .pane-title {
    @extend h1;
    @extend .field--thematic-section-title;
  }
  .gallery-slides {
    margin: 0 rhythm(1) rhythm(0) 0;
  }
  .field--thematic-section-title {
    margin-bottom: rhythm(2);
    .group-header & {
      margin-bottom: 0;
    }
  }
  .field--tr-section-more-recipes-label,
  .field-thematic-retailing-section,
  .thematic-header--wrapper,
  .pane-thematic-retailing-overview-thematic-retailing-overview-pane {
    //@extend %container;
  }
  .group-thematic-retailing-section {
    background-color: $grey--light;
    @include rhythm(2, 1, 1);
    @include clearfix;
  }
  .thematic_retailing_recipes {
    width: 100%;
    .group-header,
    .field--thematic-section-recipe,
    .view-mode-thematic_retailing_recipe_small {
      //@extend %container;
    }
    .field--thematic-section-recipes {
      @include clearfix;
      margin-bottom: 3em;
      img {
        border: solid rhythm(1) rgba(255, 255, 255, 0.9);
        box-sizing: border-box;
        margin-left: rhythm(1);
        max-width: 367px;
      }
    }
    .field--thematic-section-recipes:last-child {
      margin-bottom: 0;
    }
  }
}

.thematic-retailing--images {
  @include span-columns(5);
}

.field--thematic-section-body {
  @include span-columns(10);
  @include omega;
}



.field-thematic-retailing-section {
  &.thematic_retailing_image_left {
    //.group-right {
      position: relative;
      .field--thematic-section-body {
        overflow: hidden;
        max-height: 266px;
      }
    //}
  }
  &.thematic_retailing_image_right {
    //.group-left {
      position: relative;
      .field--thematic-section-body {
        overflow: hidden;
        max-height: 266px;
      }
    //}
  }
}

.thematic-header--wrapper {
  position: relative;
  @include rhythm(0, 0, 0, 1);
  img {
    width: 100%;
  }
  .thematic-header,
  .group-shop-category-headline {
    //    @include grid-span(10, 1, 14);
    float: none;
    &.has-image {
      position: absolute;
      bottom: rhythm(8);
      h1 {
        background-color: $transgreen;
        color: white;
        @include font-size(48px);
        @include rhythm(0, 0.25, 0.25, 0);
      }
      h2 {
        background-color: $transgreen;
        @include font-size(30px);
        @include rhythm(0, 0, 2, 0);
        color: #434343;
        padding-right: $gutter*0.5;
        padding-left: $gutter*0.5;
      }
      p {
        background-color: $transwhite;
        @include rhythm(0, 0.5, 0.5, 0);
      }
      h1,
      p {
        padding-right: $gutter*0.5;
        padding-left: $gutter*0.5;
      }
    }
  }
}