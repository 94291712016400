.recipe--full,
.recipe--full header {
  @include mq($desk, true) {
    @include clearfix;
  }
}

.recipe--full .galleryformatter {
  @include clearfix;
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(5);
    margin-bottom: 0;
  }
}

.recipe--full .recipe__content {
  @include mq($desk, true) {
    padding-left: $gutter;
    @include span-columns(10);
  }
}

.view-recipe-related-products .product-display--teaser {
  @include mq($tab, true) {
    @include span-columns(3 of 9);
    @include omega(3n);
    &:nth-child(even) {
      margin-bottom: rhythm(1);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.group-product-allergy-ingredients {
  .group-recipe-allergy,
  .group-recipe-complexity {
    @include mq($tab) {
      width: 32%;
    }
  }
}

.node-type-recipe .region--push {
  margin-bottom: -($gutter*0.5);
  margin: {
    right: -($gutter*0.5);
    left: -($gutter*0.5);
    bottom: rhythm(2);
  }
  @include mq($desk, true) {
    margin: {
      left: 0;
      right: 0;
      bottom: rhythm(3);
    }
  }
  @include mq($desk-plus, true) {
    margin: {
      top: -(rhythm(1));
    }
  }
}

.field--recipe-number {
  @include font-size(15px);
}

.field--recipe-complexity .icon--hat {
  @include icon(hat, before);
  color: #888;
}

.field--recipe-complexity.recipe-complexity-0 .icon--hat:nth-child(1),
.field--recipe-complexity.recipe-complexity-1 .icon--hat:nth-child(1),
.field--recipe-complexity.recipe-complexity-2 .icon--hat:nth-child(1) {
  color: #000;
}

.field--recipe-complexity.recipe-complexity-1 .icon--hat:nth-child(2),
.field--recipe-complexity.recipe-complexity-2 .icon--hat:nth-child(2) {
  color: #000;
}

.field--recipe-complexity.recipe-complexity-2 .icon--hat:nth-child(3) {
  color: #000;
}

.field--recipe-complexity .hat--icons {
  float: none;
}

.field--recipe-complexity .icon--hat:before {
  font-size: 2.4em;
}

.recipe--full {
  .horizontal-tabs ul.horizontal-tabs-list {
    width: auto;
  }
}
