.block--menu-menu-sb-main-menu,
.block--menu-menu-sb-main-menu--2 {
  float: left;
  @include clearfix;
  position: relative;
  z-index: 10;
  padding: 0 0 rhythm(2);
}

.block--menu-menu-sb-main-menu--2,
.block--menu-menu-sb-main-menu {
  .menu--item__link {
    color: $dark;
    padding-right: rhythm(2);
    span.title {
      display: block;
      font-family: $open-sans;
      @include font-size(36px);
      letter-spacing: 2px;
    }
    span.subline {
      border-color: $green;
      border-bottom: solid 4px transparent;
      display: inline-block;
      color: $medium;
      font-family: $pt-sans;
      @include dtransition(border-color);
      @include font-size(13px);
    }
    &:hover,
    &.active {
      color: $green;
      span.subline {
        border-color: $green;
      }
    }
  }
  .active-trail .menu--item__link {
    color: $green;
    span.subline {
      border-color: $green;
    }
  }
}