.pagination {
  display: block;
  text-align: center;
}

.pager {
  width: auto;
  max-width: none;
  display: inline-block;
  border-radius: 0.25em;
  border: 1px solid #e6e6e6;
  list-style: none;
  text-align: center;
  margin: 0;
}

.pager-item,
.pager__item,
.pager li {
  display: none;
  margin: 0;
  float: left;
  border-right: 1px solid #e6e6e6;
  @include mq($tab, true) {
    display: inline-block;
  }
  &:last-child {
    border-right: none;
  }

  &.pager__item-prev,
  &.pager__item-next {
    display: block;
  }
}

li.pager-previous,
li.pager-next {
  display: block;
  span {
    display: block;
  }
}

.pager span {
  display: none;
  @include mq($tab, true) {
    display: block;
  }
}

.pager-previous a:hover,
.pager-first a:hover,
.pager-next a:hover,
.pager-last a:hover,
.pager-item a:hover,
.pager__item a:hover {
  background-color: rgba(182, 198, 46, 0.85);
  color: #fff;
  cursor: pointer;
}


.pager-previous a,
.pager-first a,
.pager-next a,
.pager-last a,
.pager-current,
.pager-item a,
.pager__item a,
.pager__item span,
.pager__item--ellipsis {
  color: #333;
  display: block;
  padding: 0 1.4em;
  height: rhythm(2);
  line-height: rhythm(2);
}

.pager__item--current span,
.pager-current {
  background-color: $green2;
  color: #ffffff;
  pointer-events: none;
}

.pager .pager-next a:after {
  margin-left: 0;
  @include mq($tab, true) {
    margin-left: 1em;
  }
}

.pager-previous a:after {
  display: none;
}
