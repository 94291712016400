.view-shop-content .attachment {
  display: none;
  @include mq($tab, true) {
    display: none;
  }
}

.view-shop-content .attachment,
.view-shop-content .view-footer {
  //border-bottom: 1px solid #dfe0e1;
  //border-top: 1px solid #dfe0e1;
  padding: rhythm(1) 0;
  @include clearfix;
  color: #fff;
  width: 100%;
  @include mq($tab, true) {
    //margin-bottom: rhythm(1);
  }
}

.view-shop-content .attachment-ipp {
  float: right;
}

.view-shop-content .items-per-page {
  display: inline;
}

.view-shop-content .view-content {
  clear: both;
}

.view-shop-content .product-row {
  @include clearfix;
  margin-bottom: rhythm(1);
}
