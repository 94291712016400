.view-slideshow {
  display: none;
  @include outer-container;
  @include mq($tab, true) {
    display: block;
  }
}

.view-slideshow .view-content {
  box-shadow: 0px 0px 2px #999;
}

.view-slideshow .flexslider {
  margin-bottom: 0;
}
