@mixin checkout-heading() {
  background: #f1f1f1;
  border-bottom: solid 4px #b6c62e;
  color: #000;
  @include font-size(20px);
  line-height: 28px;
  font-family: $open-sans;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  //margin: 0 ($gutter/4);
  padding: rhythm(0.5);
  //width: calc(100%);
  @include mq($tab, true) {
    text-align: left;
    @include font-size(30px);
  }
}

#edit-buttons-bottom,
#edit-buttons-top {
  button:first-child {
    float: left;
    width: 50%;
    overflow:hidden;
  }
  button:nth-child(2) {
    float: right;
    width: 50%;
  }
  button:first-child,
  button:nth-child(2) {
    @include mq($tab, true) {
      width: inherit;
    }
  }
}

// @todo: Clean this mess up.
.line-item-summary .line-item-total-raw {
  font-weight: bold;
}

.line-item-summary .line-item-total-label {
  font-weight: normal;
}

// Direct ordering block
// @todo: Mobile UX is not that nice.
.block--direct-ordering {
  border: solid rhythm(1) #f1f1f1;
  padding: rhythm(1) $gutter;
  @include clearfix;
  margin-top: rhythm(2);
  position: relative;
}

.block--direct-ordering .block__title {
  @extend %tab-title;
  font-weight: bold;
  position: absolute;
  top: -(rhythm(1.5));
}

//.direct-ordering-form {
.form-item-sku {
  @include mq($tab, true) {
    float: left;
    margin-right: $gutter;
    max-width: 48%;
  }
}

.form-item-sku.form-type-textfield label {
  @include mq($tab, true) {
    float: left;
    width: 30%;
    line-height: rhythm(2);
    padding-right: $gutter;
  }
}

.form-item-sku.form-type-textfield input[type="text"] {
  @include mq($tab, true) {
    float: right;
    width: 70%;
    max-width: 100%;
    margin: 0;
  }
}

.direct-ordering-form .form-item-quantity {
  clear: both;
  float: left;
  margin: 0 $gutter rhythm(1) 0;
  @include mq($tab, true) {
    clear: none;
    margin-bottom: 0;
  }
  label {
    display: none;
  }
}

.views-form-commerce-cart-form-default tr {
  text-align: left;
}

td.views-field-commerce-unit-price,
td.views-field-commerce-total {
  text-align: right;
}

td.component-total {
  width: 90px;
}

.direct-ordering-form button.form-submit {
  @include icon(basket, after);
}

// Checkout.
#edit-buttons-top .fieldset-wrapper, #edit-buttons-bottom .fieldset-wrapper {
  padding: 0;

  button[type="submit"] {
    margin-top: 0;
  }
}

/**
  Hack to allow mobile users to select the radios on the shipping page.
  This prevents the off-canvas menu from opening via swipe but allows users to fullfil the checkout.
  Since the checkout has priority here disabling this swipe function should be ok.
 */
html:not(.slideout-open) {
  .page-checkout-shipping, .page-checkout-review {
    .region--off-canvas {
      pointer-events: none;
    }
  }
}

.commerce-checkout-form-login,
.commerce-checkout-form-address-data,
.commerce-checkout-form-shipping,
.commerce-checkout-form-review{
  fieldset > legend {
    @media screen and (max-width: 767px) {
      @include checkout-heading;
    }

  }
  button[type="submit"], a.btn {
    @media screen and (max-width: 767px) {
      font-size:1.2em;
    }

    &:not(.button-back) {
      background-color: $green2;
      color: #fff;
      margin-top: 1rem;
      font-weight: bold;
    }
  }
}

.page-checkout, .page-cart {
  button[type="submit"], a.btn {
    @media screen and (max-width: 767px) {
      font-size: 1.2em;
    }
  }
}

.checkout-form {
  @include mq($desk, true) {
    @include span-columns(10);
  }

  @include mq($desk, true) {
    #edit-login-pane-continue {
      margin-left: 1em;
    }
  }
}

.checkout-sidebar {
  @include mq($desk, true) {
    @include span-columns(5);
  }
}

.checkout-form .commerce_fieldgroup_pane__group_subscription_settings,
.checkout-form .sb_giftwrap {
  @include mq($tab, true) {
    @include span-columns(5 of 10);
  }
}

.checkout-form fieldset {
  display: block;
  width: 100%;
}

.checkout-form .sb_giftwrap {
  @include omega;
}

.checkout-form .shipping-address {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(5 of 15);
    margin-bottom: 0;
  }
}

.checkout-form .billing-address {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(5 of 15);
    margin-bottom: 0;
  }
}

.checkout-form .payment-method {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(5 of 15);
    @include omega;
    margin-bottom: 0;
  }
}

.checkout-form .email-address, .checkout-form .mail-address {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(5);
    margin-top: rhythm(1);
    margin-bottom: 0;
  }
}

.checkout-form .giftwrap {
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    @include span-columns(10);
    @include omega;
    margin-top: rhythm(1);
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }
}

.view-sb-shopping-cart-summary {
  //margin-bottom: rhythm(2);
}

.commerce-checkout-form-complete {
  @extend .checkout-form;
}

.commerce-checkout-form-complete {
  width: 100%;

  #edit-sb-checkout-completion-details {
    display: none;
  }

  h2.success {
    @media screen and (max-width: 767px) {
      margin-top: 2rem;
    }

  }
}

#edit-order-details h3 {
  @include font-size(17px);
}

#edit-commerce-coupon-coupon-add {
  float: right;
  margin-top: rhythm(1);
}

.commerce-order-handler-area-order-total {
  margin-top: rhythm(1);
}

// Checkout page custom by Marc (PLS fix this!!!)
.page-checkout .region--navigation {
  display: none;
}

.page-checkout .button-continue {
  background-color: $green2;
  color: #fff;
  &:hover {
    background-color: $green;
  }
}

.page-checkout .button-back {
  float: left;
}

div.addressfield-container-inline > div.form-item {
  clear: both;
  float: none;
  margin-right: 0;
}

.page-checkout .chosen-single {
  height: 42px;
  line-height: 42px;
}

.page-checkout .chosen-container-single .chosen-single div {
  top: 10px;
}

.page-checkout .form-type-select {
  margin-bottom: rhythm(0.5);
}

.page-checkout .chosen-container {
  width: 70% !important;
}

.form-item-commerce-payment-payment-method {
  clear: both;
  margin-bottom: rhythm(0.5);
}

.form-item-commerce-payment-payment-method input[type="radio"] {
  float: left;
  position: relative;
  top: 3px;
}

.form-item-commerce-payment-payment-method label {
  margin-left: 8px;
}

.commerce-paypal-icon {
  top: -1px;
  margin-left: 8px;
}

.commerce-paypal-icons {
  clear: both;
  margin-top: rhythm(1);
}

.commerce-paypal-icons .label {
  float: left;
}

//.page-checkout-shipping {
//  #edit-commerce-payment-payment-method {
//    @include span-columns(8);
//  }
//  #payment-details {
//    @include span-columns(8);
//
//  }
//}

//&.page-checkout-complete h2 {
//  margin-top: 20px;
//}
@include mq($tab, true){
  .commerce-checkout-form-review {
    fieldset#edit-cart {
      border: solid 20px $green-medium;
    }
    fieldset#edit-sb-checkout-agb {
      border: solid 20px #f0f4cc;
    }
  }
}

.sb_checkout_agb .button-continue {
  margin-left: $gutter;
  margin-bottom: rhythm(1);
}

.form-item-sb-checkout-agb-agb {
  border-top: solid 1px transparent;
  font-weight: bold;
  //line-height: rhythm(2);
  & + em {
    display: block;
    clear: both;
  }
}

.success {
  @include icon(checkmark, before);
  color: $green;
  margin-bottom: 30px;
  &:before {
    padding-right: 10px;
  }
}

//}
//
//.not-logged-in.page-checkout.page-checkout-checkout fieldset#edit-sb-giftwrap {
//  @include span-columns(6);
//}
//

// Sidebar
.checkout-sidebar {
  border: solid rhythm(1) $light;
  padding: rhythm(1);
  color: $dark;
}

// Checkout sidebar blocks.
.block--sb-checkout-sb-checkout-hotline > h2 {
  @include icon(phone, before, 26px) {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }
}

.block--sb-checkout-sb-checkout-secure-shopping > h2 {
  margin-top: rhythm(1);
  @include icon(medal, before, 26px) {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }
}

.block--sb-checkout-sb-checkout-payment-methods > h2 {
  margin-top: rhythm(1);
  @include icon(coin-euro, before, 26px) {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }
}

.image-wrapper {
  @include clearfix;
  margin-bottom: rhythm(0.5);
  .ssl-image {
    height: 62px;
    width: 62px;
    margin-right: 19px;
    margin-left: 4px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

// Last step trusted shop info.
.columns-2-left {
  @include mq($tab, true) {
    @include span-columns(6 of 12);
  }
}

.columns-2-right {
  @include mq($tab, true) {
    @include span-columns(6 of 12);
    @include omega;
  }
}
#sb-trusted-shop-checkout {
  margin-top: rhythm(2);
  div[id^="TsCustomCheckoutGuaranteeCard_"] {
    border: none;
  }
  div[id^="Title_"] {
    color: $dark;
    font-family: $handlee;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }
  div[class^="check_"] {
    background-image: none;
    color: $medium;
    @include icon(checkmark, before);
    &:before {
      padding-right: 5px;
    }
  }
  div[id^="Footer_"] {
    display: none;
  }
  a[id^="TsCheckoutButton_"] {
    background-color: $green2;
    color: #fff !important;
    border-radius: 0;
    font-size: 14px;
    font-family: $pt-sans;
    font-style: normal;
    font-weight: 400;
    margin-left: 27px;
    &:hover {
      background-color: $green;
    }
  }
}
