// Action links.
.page-user .action-links,
.page-user .tabs--secondary {
  float: none;
  display: none;
}

.block--sb-base-action-links li {
  list-style: none;
  @include mq($tab, true) {
    float: left;
    margin-right: $gutter*0.5;
  }
}

.block--sb-base-action-links a {
  @extend %button;
  margin-bottom: rhythm(1);
  &:after {
    display: none;
  }
  @include icon(plus-circle) {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }
}

// Primary tabs.
.page-user .tabs--primary {
  float: none;
  @include mq($desk, true) {
    display: none;
  }
}

.page-user .region--user-sidebar .tabs--primary {
  display: none;
  @include mq($desk, true) {
    display: block;
  }
}

.page-user .tabs--primary li {
  @include mq($tab) {
    width: 25%;
    &:last-child a {
      margin-right: 0;
    }
  }
  @include mq($desk, true) {
    width: auto;
    float: none;
  }
}

.page-user .tabs--primary li a {
  border: solid 1px $light;
  background: none;
  height: auto;
  color: inherit;
  padding: rhythm(0.5) ($gutter*0.5);
  text-align: left;
  margin-bottom: rhythm(0.5);
  position: relative;
  @include transition(all 0.3s ease-in-out);
  @extend h2;
  @include mq($tab, true) {
    text-align: center;
    margin-right: rhythm(0.5);
  }
  @include mq($desk, true) {
    padding: rhythm(1) $gutter;
    margin-right: 0;
  }
  &.active {
    border-color: $green2;
    cursor: pointer;
    &:hover {
      border-color: $green2;
    }
  }
  &:hover {
    border-color: $green;
    background: none;
  }
}

.page-user .tabs--primary li.active a:after {
  @include mq($tab, true) {
    background: #fff;
    border-top: solid 1px $green2;
    border-right: solid 1px $green2;
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    position: absolute;
    z-index: 10;
    @include transform(rotate(135deg));
    left: rhythm(1);
    bottom: -13px;
  }
  @include mq($desk, true) {
    @include transform(rotate(45deg));
    top: rhythm(1);
    right: -13px;
    bottom: inherit;
    left: inherit;
  }
}

.page-user .tabs--primary > li a:before {
  background-color: $green2;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  text-align: center;
  width: 35px;
  height: 35px;
  margin: 0 ($gutter*0.5) 0 0;
  padding: 6px 0 0;
  position: relative;
  z-index: 11;
  @include mq($tab, true) {
    display: block;
    width: 75px;
    height: 75px;
    margin: 0 auto rhythm(1);
    padding: 15px 0 0;
  }
}

.page-user .tabs--primary li:nth-child(1) a {
  @include icon(user) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.page-user .tabs--primary li:nth-child(2) a {
  @include icon(stack) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.page-user .tabs--primary li:nth-child(3) a {
  @include icon(bag) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

.page-user .tabs--primary li:nth-child(4) a {
  @include icon(address-book) {
    @include font-size(16px);
    @include mq($tab, true) {
      @include font-size(30px);
    }
  }
}

// Secondary tabs.
.page-user .region--user-content .tabs--secondary {
  display: block;
}

.page-user .tabs--secondary li {
  width: 50%;
}

.page-user .tabs--secondary li a {
  border: solid 1px $light;
  background: transparent;
  color: inherit;
  text-align: center;
  margin: 0 0 rhythm(1);
  @include mq($desk, true) {
    margin: 0 0 rhythm(2);
    padding: rhythm(1) 0;
  }
  @include transition(all 0.3s ease-in-out);
  &.active {
  }
  &.active,
  &:hover {
    background: transparent;
    border-color: $green2;
    color: $green2;
  }
  &.active:hover {
    border-color: $green;
  }
}
