// Dropdown sticky menu.
.region--sticky-menu .block--menu.block--menu-menu-sb-sticky-menu .block__title {
  display: block;
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu .menu--item {
  display: block;
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu {
  margin-left: 24px;
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu > .menu--item {
  position: relative;
  margin: 0 0 rhythm(1);
  &:before {
    background-color: $green2;
    display: block;
    content: '';
    height: 13px;
    width: 13px;
    position: absolute;
    left: -24px;
    top: 3px;
  }
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu > .last {
  margin: 0;
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu > .menu--item > .menu--item__link {
  display: block;
  color: $dark;
  @include font-size(16px, false);
  line-height: rhythm(1);
  &:hover {
    color: $green2;
  }
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu .menu {
  margin: 0;
  padding: 0;
}

.region--sticky-menu .block--menu-menu-sb-sticky-menu > .menu .menu .menu--item__link {
  color: $medium;
  margin: 0 14px 0 0;
  &:hover {
    color: $dark;
  }
}

// Off canvas sticky menu.
.region--off-canvas .block--menu .block__title {
  display: none;
}

.region--off-canvas .block--menu .menu--item {
  display: block;
}

.region--off-canvas .block--menu > .menu > .menu--item > .menu--item__link {
  border-top: solid 1px #e1e1e1;
  border-bottom: solid 1px #e1e1e1;
  display: block;
  color: #2d6f30;
  @include font-size(16px);
  padding: 7px rhythm(2) 6px rhythm(1);
  margin: 0;
}

.region--off-canvas .block--menu > .menu > .menu--item > .menu--item__link {
  border-top: solid 1px #555;
  border-bottom: solid 1px #333;
  background-color: #444;
  color: #fff;
  padding: 10px 1em 9px;
}

.region--off-canvas .block--menu > .menu .menu {
  margin: 0;
  padding: 0;
}

.region--off-canvas .block--menu > .menu .menu .menu--item__link {
  background-color: #555;
  color: #fff;
  padding: 7px 0 6px rhythm(2);
}

// Legacy cause drupal sucks sometimes.
.block--menu-menu-sb-sticky-menu--2,
.block--menu-menu-sb-sticky-menu--3,
.block--menu-menu-sb-sticky-menu--4,
.block--menu-menu-sb-sticky-menu--5 {
  @extend .block--menu-menu-sb-sticky-menu;
}
