.page-products {
  @include greenFlexsliderNavigation;
  margin: 0 0 rhythm(1.5);
  .flex-direction-nav {
    right: calc(3rem + 10px);
    @include mq($tab, true) {
      top: -70px;
    }
    @include mq($desk, true) {
      margin-right: $gutter;
    }
  }
}

.page-products .field--title-field {
  text-align: center;
  @include mq($tab, true) {
    text-align: left;
    padding-right: 190px;
  }
  @include mq($desk, true) {
    @include outer-container();
  }
}

.page-products .field--page-products {
  @include outer-container();
}

.page-products .flexslider {
  margin-top: rhythm(1.5);
  @include mq($tab, true) {
    margin-right: -(rhythm(2));
    margin-left: -(rhythm(2));
  }
}

.page-products .flexslider .block__title {
  @include icon(stack-list) {
    margin-right: 6px;
  }
  @include font-size(18px);
  font-family: $pt-sans;
  font-weight: bold;
  text-transform: uppercase;
  padding: rhythm(0.5) rhythm(2) rhythm(0.5);
}

.page-products .flexslider .flex-viewport {
  padding: 0 rhythm(2);
  // The flexslider must be full size in order to have the "new product" flag not cut off by overflow:hidden. Therefore
  // we add these white boxes on the left and right side so simulate a margin.
  @include mq($tab, true) {
    &:before,
    &:after {
      background-color: #fff;
      content: '';
      display: block;
      height: 100%;
      width: rhythm(2);
      position: absolute;
      top: 0;
    }
    &:before {
      left: 0;
      width: $gutter;
      z-index: 10;
    }
    &:after {
      right: 0;
    }
    img {
      display: inherit;
      width: inherit;
    }
  }
}
