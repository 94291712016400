// Shopping cart on shopping cart page and summary on checkout.
.view-commerce-cart-form {
  table {
    margin-bottom: 0;
  }
  thead {
    display: none;
  }
  tbody tr {
    border-bottom: solid 1px $light;
    display: block;
    @include clearfix;
    //text-align: center;
    padding: rhythm(0.5) 0;
    position: relative;
    @include mq($desk, true) {
      padding: rhythm(1) 0;
    }
  }
  tbody tr td {
    border: none;
    display: block;
    padding: 0;
    margin: 0;
  }
  .cart-product-image {
    float: left;
    @include clearfix;
    margin-right: $gutter;
    img {
      // @todo: Adjust image style.
      height: 68px;
      width: 64px;
    }
  }
  .views-field-line-item-title {
    @include font-size(16px);
    overflow: hidden;
    margin-right: 42px;
  }
  .views-field-commerce-unit-price {
    text-align: left;
    overflow: hidden;
    &:before {
      content: "Stückpreis: ";
    }
  }
  .views-field-quantity {
    float: left;
    overflow: hidden;
    &:before {
      content: "Anzahl: ";
    }
  }
  .views-field-edit-quantity {
    float: left;
    margin-top: rhythm(0.5);
    overflow: hidden;
  }
  .views-field-edit-delete {
    float: left;
    position: absolute;
    right: 0;
    top: 0;
  }
  .views-field-edit-delete button {
    width: auto;
    border: none;
    @include mq($desk, true) {
      margin-top: rhythm(0.5);
    }
    &:after {
      margin-left: 0;
      @include mq($tab, true) {
        margin-left: 8px;
      }
    }
    span {
      display: none;
      @include mq($tab, true) {
        display: inline;
      }
    }
  }
  .views-field-commerce-total {
    text-align: right;
    font-weight: bold;
    overflow: hidden;
    height: 42px;
    width: auto;
    padding: rhythm(1) 8px 0 0;
  }

  .links {
    list-style: none;
    margin: 0;
    @include mq($desk, true) {
      margin: rhythm(1) 0 0;
    }
    a {
      @extend %button;
    }
  }
}

// Give the delte link a nice trash icon.
.form-submit.delete-line-item {
  @include icon(remove, after) {
    position: relative;
    top: 1px;
    margin-left: 8px;
  }
}

// Highlight the go to cart button by making him green.
.commerce-line-item-actions button {
  background-color: $green2;
  color: #fff;
  @include icon(cart) {
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
  &:hover {
    background-color: $green;
  }
}
