.social__title {
  @include icon(share3) {
    margin-right: 6px;
  }
  @include font-size(18px);
  font-family: $pt-sans;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: rhythm(2);
  padding: rhythm(0.5) rhythm(2) rhythm(0.5) 0;
}

.field--print-recipe {
  display: none;
  @include mq($desk, true) {
    display: block;
    width: 49%;
    float: right;
  }
}

.print-button {
  @extend %button2;
  &:after {
    display: none;
  }
  @include icon(print, before, 20px) {
    transition: all 0.3s ease 0.2s;
    color: #fff;
    padding-right: 20px;
    position: relative;
    top: 3px;
  }
  &:hover:before {
    color: #fff;
  }
}

.node--images .field--print-recipe {
  margin-bottom: rhythm(0.5);
}

.field--print-recipe a {
  display: block;
  margin: 0;
}

.socials--actions li {
  float: left;
  margin: 0;
  @include mq($tab, true) {
    float: none;
  }
}

.socials--actions {
  list-style: none;
  margin: 0;
}

.social--action {
  display: inline-block;
  width: rhythm(2);
  height: rhythm(2);
  color: #fff;
  //font-family: $sans-serif;
  line-height: rhythm(2);
  transition: opacity 0.3s linear;
  margin-bottom: rhythm(0.5);
  position: relative;
  @include mq($tab, true) {
    display: block;
    width: 49%;
    float: left;
  }
}

.social--action:hover {
  opacity: 0.8;
  text-decoration: none;
  cursor: pointer;
}

.social--action:before {
  display: block;
  float: left;
  color: #fff;
  height: rhythm(2);
  width: rhythm(2);
  padding-left: 12px;
  position: relative;
  padding-top: 12px;
  @include mq($tab, true) {
    padding-left: 12px;
    padding-top: 11px;
    height: 46px;
    width: 38px;
  }
}

.social--action span {
  color: #fff;
  display: none;
  @include mq($tab, true) {
    display: inline;
  }
}

.social--action--facebook {
  background-color: #476191;
  @include icon(facebook, before, 20px) {
    padding-top: 10px;
    padding-left: 10px;
    @include mq($tab, true) {
      padding-top: 8px;
    }
  }
}

.social--action--twitter {
  background-color: #29c7fd;
  @include icon(twitter, before, 20px);
  @include mq($tab) {
    float: right;
  }
}

.social--action--google-plus {
  background-color: #d93f2d;
  @include icon(google, before, 20px);
}
