.block--menu-menu-sb-experience {
  @include outer-container;
  display: none;
  @include media($desk) {
    display: block;
    background-color: $grey--light;
    border-radius: 2px;
    clear: both;
    overflow: hidden;
  }
}

.block--menu-menu-sb-experience .menu--item {
  @include media($desk) {
    width: calc(100% / 5);
    float: left;
  }
}

.block--menu-menu-sb-experience {
  .menu--item__link {
    @include media($desk) {
      padding: rhythm(0.5) rhythm(1);
      .title {
        color: $grey--dark;
        font-family: $open-sans;
        @include font-size(26px);
        letter-spacing: 2px;
      }
      &:hover {
        background-color: #F6F6F6;
      }
    }
    .subline {
      display: none;
      @include media($desk) {
        display: block;
        color: $medium;
        font-family: $pt-sans;
        @include font-size(13px);
      }
      @include media($max-width, true) {
        display: inline;
        margin-left: 4px;
      }
    }
  }
  .active-trail .menu--item__link {
    background-color: $green-medium;
    &:hover {
      background-color: $green2;
    }
  }
}

.block--menu-menu-sb-experience--2,
.block--menu-menu-sb-experience--3,
.block--menu-menu-sb-experience--4 {
  @extend .block--menu-menu-sb-experience;
}
