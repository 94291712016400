%box {
  margin: 0 0 rhythm(0.5);
  @include mq($tab, ture) {
    border: solid 20px $light;
    margin: 0 0 rhythm(1);
    padding: rhythm(1);
  }
}

%fieldset,
fieldset {
  border: none;
  margin: 0 0 rhythm(0.5);
  padding: 0;
  @include mq($tab, ture) {
    border: solid 20px $light;
    margin: 0 0 rhythm(1);
    padding: rhythm(1);
  }
  .ui.accordion & .title:not(.ui) {
    padding: rhythm(0.5) ($gutter*0.5);
  }
  .fieldset-wrapper,
  .ui.accordion:not(.styled) & .title ~ .content:not(.ui):last-child,
  .ui.accordion:not(.styled) & .title ~ .content:not(.ui) {
    padding: rhythm(1) ($gutter*0.5);
    .fieldset-wrapper,
    .content {
      padding: 0;
    }
  }
  .fieldset-wrapper {
    padding: rhythm(1) 0;
    @include mq($tab, true) {
      padding: rhythm(1) ($gutter*0.5);
    }
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    legend {
      display: none;
    }
  }
}

fieldset.collapsible .fieldset-legend,
legend p {
  display: inline-block;
}
%legend,
legend {
  border: solid 1px $light;
  width: 100%;
  font-weight: bold;
  padding: rhythm(0.5) ($gutter*0.5);
  font-family: $handlee;
  @include mq($tab, ture) {
    border: solid 1px $light;
    width: auto;
    font-size: 16px;
  }
}

%pseudo_legend {
  @extend %legend;
  @include mq($tab, true) {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: -32px;
  }
}
