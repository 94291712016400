.field--is-recurring--wrapper {
  margin-bottom: rhythm(1);
}

.commerce-order .field--address {
  @extend %box;
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
  legend {
    display: none;
  }
  @include mq($tab, true) {
    @include span-columns(5 of 10);
  }
}
