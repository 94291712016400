//.page-erlebniswelt,
//.node-type-party,
//.node-type-event,
//.node-type-page {
//  .breadcrumb--wrapper {
//    border-bottom: none;
//    margin-bottom: 0;
//  }
//}
.node-type-page {
  .region--breadcrumb,
  .page__title,
  .region--view {
    //  .region--content .region--content {
    @include outer-container;
  }
  .region--content .region--content {
    margin-top: 0;
  }
  .page__title {
    margin-top: rhythm(1);
  }
}

.not-front.page-erlebniswelt .l-main-inner .view-slideshow,
.not-front.page-erlebniswelt .l-main-inner,
.not-front.node-type-page .l-main-inner {
  margin-right: 0;
  margin-left: 0;
  max-width: 100%;
}

.node-type-page .region--content-header {
  @include clearfix;
  padding: rhythm(2) 0;
}

.node-type-page {
  @include media($tab, true) {
    .region--content-inner {
      @include span-columns(10);
    }
    .region--sidebar {
      @include span-columns(5);
    }
  }
}

.page--full .field-label {
  font-weight: normal;
  border-bottom: solid 1px $light;
  display: block;
  @include font-size(16px);
  @include rhythm(1.5, 0, 0, 0.5);
}

.photoswipe-gallery {
  @include clearfix;
}

.photoswipe-gallery a {
  border: solid 4px $green2;
  @include clearfix;
  float: left;
  display: block;
  margin-bottom: rhythm(0.5);
  width: 49%;
  margin-right: 2%;
  @include media($tab, true) {
    width: 32%;
    margin-right: 2%;
  }
  @include media($desk, true) {
    width: 19%;
    margin-right: 1.2%;
  }
}

.photoswipe-gallery a {
  &:nth-of-type(2n) {
    margin-right: 0;
    @include media($tab, true) {
      margin-right: 2%;
    }
    @include media($desk, true) {
      margin-right: 1.2%;
    }
  }
  &:nth-of-type(3n) {
    @include media($tab, true) {
      margin-right: 0;
    }
    @include media($desk, true) {
      margin-right: 1.2%;
    }
  }
  &:nth-of-type(5n) {
    @include media($desk, true) {
      margin-right: 0;
    }
  }
}
