// @todo: this nees cleanup!!!

.block--sb-allergy-filter-sticky-user-menu .block__title {
  display: none;
  @include mq($desk, true) {
    display: block;
  }
}

.block--menu-menu-sb-user-menu .menu--item {
  float: left;
  position: relative;
}

.sticky .menu-toggle,
.block--menu-menu-sb-user-menu .menu--item > span > a {
  display: block;
  color: #fff;
  height: 43px;
  cursor: pointer;
  @include font-size(18px);
  line-height: 43px;
  @include mq($desk, true) {
    padding: 0 rhythm(1);
    height: 47px;
    line-height: 47px;
  }
  &.shopping-cart {
    @include mq($tab) {
      padding: 0 rhythm(1);
    }
  }
  &.open-menu-item {
    background-color: $green;
  }
}

// Make menu icon appear without text on smallest screens.
.sticky .menu-toggle {
  @media screen and (max-width: 350px) {
    width: 50px;
    font-size: 0;
    line-height: 3.75rem;
    position: relative;
    z-index: 199;
  }
}

.slideout-open .sticky .menu-toggle {
  @media screen and (max-width: 350px) {
    position: static;
  }
}

// Make smaller menu item clickable.
html:not(.slideout-open) {
  @media screen and (max-width: 350px) {
    .region--off-canvas {
      pointer-events: none;
    }
  }
}

.sticky .menu-toggle:hover,
.sticky .menu-toggle.open-menu-item,
.block--menu-menu-sb-user-menu .menu--item > span a:hover,
.block--menu-menu-sb-user-menu .menu--item > .open-menu-item a,
.block--menu-menu-sb-user-menu .menu--item > span a.active {
  background-color: $green;
  &:before {
    color: $dark;
  }
}

.sticky .menu-toggle:before,
.block--menu-menu-sb-user-menu .menu--item > span a:before {
  color: $medium;
  @include dtransition(color);
  position: relative;
  top: 1px;
  @include mq($tab, true) {
    margin-right: rhythm(0.5);
  }
}

.sticky .menu-toggle {
  background-color: #333;
  float: left;
  @include icon(menu, before, 18px);
  padding: 0 $gutter*0.5;
  @include mq($desk, true) {
    background-color: transparent;
  }
}

.block--menu-menu-sb-user-menu .menu--item:nth-child(1) > span a {
  @include icon(user, before, 18px);
  .not-logged-in & {
    @include icon(key, before, 18px);
  }
}

.block--menu-menu-sb-user-menu .menu--item:nth-child(2) > span a {
  @include icon(search, before, 18px);
}

.block--menu-menu-sb-user-menu .cart-title--count {
  background-color: #fff;
  border-radius: 50%;
  color: $orange;
  float: left;
  height: 24px;
  width: 24px;
  text-align: center;
  @include font-size(14px);
  margin-right: 6px;
  margin-top: 8px;
  padding-top: 2px;
  @include mq($tab, true) {
    margin-top: 8px;
  }

}

.block--menu-menu-sb-user-menu .cart-title-wrapper {
  float: left;
  padding-left: 12px;
  position: relative;
  @include icon(cart, after, 18px) {
    color: #fff;
    @include dtransition(color);
    margin-right: rhythm(0.5);
    @include mq($tab, true) {
      color: $medium;
    }
  }
  @include mq($tab, true) {
    padding-left: 0;
  }
  &:hover:after {
    color: $dark;
  }
}

.block--menu-menu-sb-user-menu .cart-title {
  float: right;
}

.block--menu-menu-sb-user-menu .cart-shipping-free-text {
  float: right;
  @include font-size(9px, 1.7);
  line-height: 1em;
  padding-left: 1em;
  margin-top: 15px;
}

.region--scroll-menu {
  float: right;
  .menu--item {
    display: none;
    @include mq($desk, true) {
      display: block;
    }
  }
  .sb_allergy_filter_cart {
    display: block;
  }
  .cart-title,
  .cart-shipping-free-text {
    display: none;
    @include mq($tab, true) {
      display: block;
    }
  }
}

.block--menu-menu-sb-user-menu .menu--item.sb_allergy_filter_cart .ui.popup {
  width: 800px;
  max-width: 800px;
  & > div {
    margin-bottom: rhythm(2);
  }
}

.ui.popup {
  overflow: auto;
  padding: rhythm(2);
}


.sticky .menu-popup.ui.popup:before,
.block--menu-menu-sb-user-menu .menu--item .ui.popup:before {
  display: none;
}

.block--menu-menu-sb-user-menu .menu--item .ui.popup {
  border-radius: 0;
  top: 47px !important;
  right: 0 !important;
  margin: 0;
}

.sticky .menu-popup.ui.popup {
  border-radius: 0;
  top: 47px !important;
  left: 0 !important;
  margin: 0;
  overflow: auto;
  max-width: inherit;
  width: 100%;

  .expanded .menu--item {
    float: left;
  }
  .block__title {
    border-bottom: solid 2px $green2;
    padding: rhythm(0.5) 0;
    margin-bottom: 0;
    &.active {
      border-color: $green;
    }
  }
  nav > form,
  .block__content,
  nav > .menu {
    padding: rhythm(1) 0;
  }
}

.region--sticky-search .block__content p {
  margin: rhythm(0.25) 0;
}

.block--sb-allergy-filter-sticky-user-menu {
  .menu--item {
    display: block;
  }
  .menu--item__link {
    border-top: solid 1px #555;
    border-bottom: solid 1px #333;
    background-color: #444;
    display: block;
    color: #fff;
    padding: 9px 1em 9px;
    margin: 0;
    @include mq($desk, true) {
      border: none;
      background-color: transparent;
      color: #333;
      padding: 0 0 rhythm(0.5);
    }
  }
  .menu--item__link:before {
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
  .menu--item:nth-child(1) .menu--item__link {
    border-top: none;
    @include icon(user);
  }
  .menu--item:nth-child(2) .menu--item__link {
    @include icon(address-book);
  }
  .menu--item:nth-child(3) .menu--item__link {
    @include icon(stack-list);
  }
  .menu--item:nth-child(4) .menu--item__link {
    @include icon(file);
  }
  .menu--item:nth-child(5) .menu--item__link {
    @include icon(exit);
  }
}

// Change color for cart button when it has items.
#block-menu-menu-sb-user-menu {
  ul.menu {
    & > li.cart-has-items > span > a {
      background-color: #d75d30;
      background-color: #dd734c;
    }
  }
}

// Legacy cause drupal sucks sometimes.
.block--sb-allergy-filter-sticky-user-menu--2 {
  @extend .block--sb-allergy-filter-sticky-user-menu;
}

