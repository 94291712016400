.region--slideshow {
  background: $slideshow-background-image no-repeat center center;
  background-size: cover;
  @include mq($tab) {
    margin-right: -(rhythm(2));
    margin-left: -(rhythm(2));
  }
  @include mq($tab, true) {
    margin-right: 0;
    margin-left: 0;
    padding: rhythm(2) 0;
  }
}
