.party--full {
  @extend .product-display--full;
}

.party--full .party__content {
  @include mq($desk, true) {
    @include span-columns(10);
  }
}

.party--full .field--product {
  @include mq($tab, true) {
    width: 50%;
    float: right;
  }
}
