.flexslider {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.flex-control-nav {
  z-index: 10;
  bottom: -2px;
  @include mq($tab, true) {
    bottom: 16px;
  }
}

// Green flexslider navigation.
@mixin greenFlexsliderNavigation {
  .flexslider {
    position: relative;
  }

  .flex-direction-nav {
    @include clearfix;
    position: relative;
    float: right;
    margin-top: rhythm(1);
    right: rhythm(2);
    @include mq($tab, true) {
      position: absolute;
      margin-top: 0;
      top: -(rhythm(1));
      right: rhythm(2);
    }
  }

  .flex-direction-nav li {
    float: left;
  }

  .flex-direction-nav a {
    background: $green2;
    display: block;
    width: auto;
    height: 25px;
    color: white;
    @include font-size(15px, false);
    line-height: 25px;
    font-family: $pt-sans;
    text-align: center;
    text-shadow: none;
    z-index: 30;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 1;
    overflow: visible;
    position: static;
    padding: 0 8px;
    &:before {
      width: 0;
      height: 0;
      bottom: 0;
      position: absolute;
      content: "";
    }
  }

  .flex-direction-nav .flex-prev {
    margin-right: 4px;
  }

  .flex-direction-nav .flex-prev:before {
    border-top: 11px solid transparent;
    border-right: 10px solid $green2;
    border-bottom: 14px solid transparent;
    left: -10px;
  }

  .flex-direction-nav .flex-next:before {
    border-top: 11px solid transparent;
    border-left: 10px solid $green2;
    border-bottom: 14px solid transparent;
    right: -10px;
  }
}

.optionset-galleryformatter-navigation {
  @include greenFlexsliderNavigation;
  margin-top: rhythm(0.5);
  .flex-direction-nav {
    top: inherit;
    right: 0;
    bottom: -(rhythm(0.5));
    z-index: 9;
  }
}

.flex-control-paging li a {
  background: $green;
  &:hover {
    background: $green2;
  }
}
.flex-control-paging li a.flex-active {
  background: $green2;
}
