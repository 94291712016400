.l-branding {
  padding: rhythm(2) 0;
  @include outer-container;
  display: none;
  @include mq($tab, true) {
    display: block;
  }
}

.l-branding .site-logo {
  display: block;
  height: 96px;
  float: left;
  background: #57ab55;
  padding: 11px;
}

.l-branding .block__content {
  float: right;
}

.l-branding .block__content > img,
.l-branding .block__content > div {
  max-width: 90px;
  max-height: 90px;
  margin: 0 10px !important;
}

#sb-trusted-shop {
  width: 190px;
  max-width: 190px;
  position: relative;
}

#reviewsAggregationOutput {
  text-align: center;
  float: right;
}

#reviewsAggregationOutput .fivestar-basic {
  margin: -2px 0 6px 5px;
}

.trusted-shops-review__title {
  @include font-size(12px);
  color: #999;
}

.trusted-shops-review__rating {
  color: #111;
  @include font-size(18px);
  text-transform: uppercase;
}

[itemprop="ratingValue"] {
  color: #111;
  font-weight: bold;
}

[itemprop="ratingCount"] {
  display: none;
}
