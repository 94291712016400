div.horizontal-tabs {
  clear: both;
  border: none;
}

.horizontal-tabs ul.horizontal-tabs-list {
  background-color: transparent;
  border: none;
  position: relative;
  top: rhythm(1.5);
  left: rhythm(2);
  display: none;
  @include mq($tab, true) {
    display: inline-block;
  }
}

.horizontal-tabs ul.horizontal-tabs-list li.selected,
.horizontal-tabs ul.horizontal-tabs-list li {
  border: none;
  margin-right: $gutter*0.5;
  @include mq($tab, true) {
    float: left;
  }
  strong {
    color: $dark;
    font-weight: normal;
  }
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: block;
  @include mq($tab, true) {
    display: none;
  }
}

.horizontal-tabs .horizontal-tab-hidden {
  position: static;
  @include mq($tab, true) {
    position: absolute;
  }
}

.horizontal-tabs fieldset.horizontal-tabs-pane {
  @extend fieldset;
}

%tab-title,
.horizontal-tabs ul.horizontal-tabs-list li a,
.horizontal-tabs ul.horizontal-tabs-list li.selected a {
  box-shadow: 1px 4px 4px rgba(29, 29, 27, 0.25);
  border: solid 1px #eee;
  background-color: #fff;
  font-family: $handlee;
  //font-weight: normal;
  @include font-size(16px);
  height: rhythm(2);
  line-height: rhythm(2);
  padding: 0 rhythm(1);
  &:hover {
    strong {
      font-weight: bold;
    }
    background-color: #fff;
  }
}

.horizontal-tabs ul.horizontal-tabs-list li.selected a {
  border-bottom: solid 2px $green;
  strong {
    font-weight: bold;
  }
}
