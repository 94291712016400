.block--block-1 {
  color: $grey--light;
  text-align: center;
  @include mq($tab, true) {
    @include span-columns(5);
    text-align: left;
  }
}

.block--block-1 .block__title {
  color: #fff;
}
