.product-display--teaser {
  border: solid 1px $light;
  @include clearfix;
  transition: border-color 0.2s linear;
  text-align: center;
  padding: rhythm(1) rhythm(0.5);
  margin-bottom: rhythm(0.5);
  @include mq($tab, true) {
    @include span-columns(4 of 12);
    margin-bottom: 0;
  }
  &:hover {
    border-color: $green2;
  }
  &.stock-status-0:hover {
    border-color: $orange;
  }
  // Inside a flexslider, flexslider will set the width properties.
  .flexslider & {
    width: 100%;
  }
}

// The clickable top part of the product teaser.
.product-display--teaser .product-header {
  position: relative;
  margin: rhythm(0.5) 0;
  &:after {
    content: '';
    border-top: solid 1px #ccc;
    width: 30%;
    display: block;
    margin: 10px auto;
  }
  &:before {
    background-color: #57ab55;
    width: 7px;
    height: 7px;
    content: '';
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    transform: rotate(45deg);
  }
  // Use the red color for the little dot, if the product is not available.
  &.product--status-0:before {
    background-color: #d75d30;
  }
}

.product-display--teaser img {
  float: none;
}

.product-display--teaser h2 {
  display: table;
  width: 100%;
  clear: both;
  // Use min-height in order to have the same height when its one or two text rows.
  min-height: rhythm(2);
  font-family: $pt-sans;
  @include font-size(18px);
  margin-bottom: rhythm(0.5);
  font-weight: 700;
  @include mq($tab, true) {
    font-weight: normal;
  }
  // Set a hover indicator when container link is hovered.
  .product-display--teaser a:hover > & {
    color: $green;
  }
}

// Hack to make sure that the one row headlines are positioned in the center of the available space.
.product-display--teaser h2 span {
  display: table-cell;
  vertical-align: middle;
}

.product-display--teaser .field--read-more {
  opacity: 0;
  position: absolute;
  display: block;
  text-align: center;
  top: 35%;
  width: 100%;
  @include transition(opacity 0.3s linear);
  left: 0;
}

// Show the read more link on hover of the product header.
.product-display--teaser .product-header:hover .field--read-more {
  opacity: 1;
}

.product-display--teaser .field--read-more .field--node-link {
  background: $green2;
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  padding: 3px 6px;
  @include icon(info) {
    margin-right: 6px;
    position: relative;
    top: 1px;
  }
}

// The is-new flag.
.product-display--teaser .product-new {
  background: $green2;
  color: white;
  width: 48px;
  height: 25px;
  @include font-size(15px, 25px);
  line-height: 25px;
  font-family: $pt-sans;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: auto;
  left: -21px;
  z-index: 3;
  &:before {
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-left: 10px solid $green2;
    border-bottom: 14px solid transparent;
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0px;
  }
  &:after {
    width: 0;
    height: 0;
    border-top: 10px solid #232323;
    border-left: 10px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
  }
}

// Product price and tax info.
.product-display--teaser .commerce-product-extra-field-commerce-price {
  display: inline-block;
  @include font-size(20px);
  font-weight: bold;
  margin: rhythm(0.5) 0;
}

.product-display--teaser .field--tax-shipping,
.product-display--teaser .commerce-product-extra-field-field-product-size-unit {
  display: inline-block;
  @include font-size(11px, false);
  line-height: 1em;
  text-align: left;
  position: relative;
  top: 2px;
  left: 6px;
}

// Form item quantity on product--teaser.
.product-display--teaser .field--product-reference {
  text-align: center;
}

.product-display--teaser .form-item-quantity {
  display: inline-block;
  transition: all 0.3s ease 0.2s;
  margin: 0;
  position: relative;
  @include mq($desk, true) {
    transform: translate(30px, 0);
    opacity: 0;
  }
}

.product-display--teaser .form-item-quantity:before {
  content: '\25B6';
  display: block;
  font-size: 80%;
  transform: rotate(90deg);
  color: #2a2a29;
  position: absolute;
  right: 14px;
  top: 12px;
  z-index: 1;
}

.product-display--teaser .form-item-quantity label {
  display: none;
}

.product-display--teaser:hover .form-item-quantity {
  @include mq($desk, true) {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.product-display--teaser .form-item-quantity select {
  display: inline-block;
  height: rhythm(2);
  box-sizing: border-box;
  color: #2a2a29;
  text-transform: uppercase;
  padding: 8px 30px 8px 15px;
  @include font-size(12px);
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #dfe0e1;
  background-color: transparent;
  position: relative;
  top: -2px;
  z-index: 2;
  &:focus {
    border-color: $green2;
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
}

// Add to cart button.
.product-display--teaser:hover .field--product-reference button {
  @include mq($desk, true) {
    transform: translate(0, 0);
  }
}

.product-display--teaser .field--product-reference .form-wrapper {
  display: none;
}

.product-display--teaser .field--product-reference button {
  display: inline-block;
  width: auto;
  @include mq($desk, true) {
    transform: translate(-28px, 0);
  }
}

.product-display--teaser[max-width~="255px"] .field--product-reference button {
  @include icon(basket);
  span {
    display: none;
  }
}
