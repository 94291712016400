.line-item-summary {
  background-color: #666;
  border-top: solid 1px #555;
  color: #fff;
  height: rhythm(2);
  line-height: rhythm(2);
  margin-bottom: rhythm(1);
}

.line-item-summary .line-item-quantity {
  margin-left: 8px;
}

.line-item-summary .line-item-total {
  margin-right: 8px;
}
