.news--teaser {
  @include clearfix;
  margin-bottom: rhythm(1);
  @include mq($tab, true) {
    margin-bottom: rhythm(2);
  }
}

.not-front .news--teaser .news__title {
  @include mq($desk, true) {
    margin-top: rhythm(0.5);
  }
}

.news--teaser .news__content {
  @include mq($tab, true) {
    @include span-columns(9);
    @include shift(0.5);
    @include omega;
  }
}

.field--news-category {
  @include font-size(11px);
  text-transform: uppercase;
}
